import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import logo from "../images/Logo.jpg";
import editIcon from "../images/edit.svg";
import trashIcon from "../images/trash.svg";
import "./styles/Form.css";
import "./styles/AccountList.css";
import "./styles/AccountAndTransactions.css";

function AccountPage() {
  const [formData, setFormData] = useState({
    accountType: "1", // Set the default value to "1" which corresponds to "Unpaid Purchase"
    accountName: "",
    address: "",
    town: "",
    registrationType: "",
    gstNumber: "",
  });
  const [tableData, setTableData] = useState([]);
  const [accountTypes, setAccountTypes] = useState([]);
  const [registrationTypes, setRegistrationTypes] = useState([]);
  const [editIndex, setEditIndex] = useState(-1);
  const [searchInput, setSearchInput] = useState("");
  const [error, setError] = useState(null);
  const [showGstPopup, setShowGstPopup] = useState(false);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const token = localStorage.getItem("token"); // Changed to localStorage
        const [
          accountTypeResponse,
          registrationTypeResponse,
          accountsResponse,
        ] = await Promise.all([
          fetch("http://kliqsoft.in/SarabukantaraoAPI/api/AccountTypes", {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }),
          fetch("http://kliqsoft.in/SarabukantaraoAPI/api/RegistrationTypes", {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }),
          fetch("http://kliqsoft.in/SarabukantaraoAPI/api/Account", {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }),
        ]);

        if (
          !accountTypeResponse.ok ||
          !registrationTypeResponse.ok ||
          !accountsResponse.ok
        ) {
          throw new Error("Failed to fetch initial data");
        }

        const accountTypeData = await accountTypeResponse.json();
        const registrationTypeData = await registrationTypeResponse.json();
        const accountsData = await accountsResponse.json();

        setAccountTypes(accountTypeData);
        setRegistrationTypes(registrationTypeData);
        setTableData(accountsData);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchInitialData();

    const boxes = document.querySelectorAll(".input-box");
    boxes.forEach((box) => box.classList.add("focus"));

    // Cleanup effect for memory leaks
    return () => {
      boxes.forEach((box) => box.classList.remove("focus"));
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "accountType" && value === "2") {
      setShowGstPopup(true);
    }
  };

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleGstChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      gstNumber: e.target.value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const token = localStorage.getItem("token"); // Changed to localStorage
      const response = await fetch(
        "http://kliqsoft.in/SarabukantaraoAPI/api/Account",
        {
          method: editIndex === -1 ? "POST" : "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const newAccount = await response.json();
      if (editIndex === -1) {
        setTableData((prevData) => [newAccount, ...prevData]);
      } else {
        const updatedData = [...tableData];
        updatedData[editIndex] = formData;
        setTableData(updatedData);
        setEditIndex(-1);
      }
      setFormData({
        accountType: "1", // Reset the default value to "Unpaid Purchase"
        accountName: "",
        address: "",
        town: "",
        registrationType: "",
        gstNumber: "",
      });
      setShowGstPopup(false);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleEdit = (index) => {
    setFormData({ ...tableData[index] });
    setEditIndex(index);
  };

  const handleDelete = async (index) => {
    try {
      const token = localStorage.getItem("token"); // Changed to localStorage
      const accountId = tableData[index].id;
      const response = await fetch(
        `http://kliqsoft.in/SarabukantaraoAPI/api/Account/${accountId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const newData = [...tableData];
      newData.splice(index, 1);
      setTableData(newData);
      setEditIndex(-1);
    } catch (error) {
      setError(error.message);
    }
  };

  const filteredData = tableData.filter((data) =>
    data.accountName.toLowerCase().includes(searchInput.toLowerCase())
  );

  return (
    <div>
      <header className="main-header">
        <NavLink to="/">
          <img src={logo} alt="Kliq Soft" className="logo" />
        </NavLink>
      </header>

      <main>
        <div className="account-creation transactions">
          <p>Account Creation</p>
        </div>
        <div className="goback">
          <NavLink to="/transactions">
            <svg
              fill="#185492"
              className="left-arrow"
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 20 20"
            >
              <path d="M10,0A10,10,0,1,0,20,10,10,10,0,0,0,10,0Zm6,11H7.38l2.376,2.375L8.342,14.789,3.607,10.055,8.342,5.322,9.756,6.736,7.49,9H16Z" />
            </svg>
          </NavLink>
        </div>
        <div className="form-container">
          <form className="form form1" onSubmit={handleSubmit}>
            <div className="input-box focus active-grey">
              <label className="input-label">Account Type</label>
              <select
                className="input-1"
                name="accountType"
                value={formData.accountType}
                onChange={handleInputChange}
              >
                {accountTypes.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="input-box focus">
              <label className="input-label">Account Name *</label>
              <input
                type="text"
                id="inputField"
                className="input-1"
                name="accountName"
                value={formData.accountName}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="input-box focus">
              <label className="input-label">Address 1</label>
              <input
                type="text"
                className="input-1"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-box focus">
              <label className="input-label">Town</label>
              <input
                type="text"
                className="input-1"
                name="town"
                value={formData.town}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-box focus active-grey">
              <label className="input-label">Registration Type</label>
              <select
                className="input-1"
                name="registrationType"
                value={formData.registrationType}
                onChange={handleInputChange}
              >
                {registrationTypes.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="listAndSave">
              <button
                id="submitButton"
                className="btn btn-save"
                disabled={!formData.accountName}
              >
                {editIndex === -1 ? "Save Account" : "Update Account"}
              </button>
            </div>
          </form>
        </div>
        {error && <div className="error-message">{error}</div>}
        <div className="item-search">
          <input
            type="text"
            name="search"
            id="search"
            className="account_search"
            placeholder="A/c Name"
            value={searchInput}
            onChange={handleSearchChange}
          />
          <button className="btn btn-search">View</button>
        </div>
        <div
          className="account-list"
          style={{ overflowX: "auto", marginBottom: "3.5rem" }}
        >
          <table>
            <thead>
              <tr>
                <th scope="col">A/c Name</th>
                <th scope="col">Address</th>
                <th scope="col" colSpan="2" style={{ opacity: 0 }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((data, index) => (
                <tr key={index}>
                  <td>{data.accountName}</td>
                  <td>
                    {data.address}, {data.town}
                  </td>
                  <td>
                    <img
                      src={editIcon}
                      alt="Edit"
                      onClick={() => handleEdit(index)}
                    />
                  </td>
                  <td>
                    <img
                      src={trashIcon}
                      alt="Delete"
                      onClick={() => handleDelete(index)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </main>

      {showGstPopup && (
        <div className="gst-popup-overlay">
          <div className="gst-popup">
            <div className="popup-content">
              <label>Enter GST Number:</label>
              <input
                type="text"
                value={formData.gstNumber}
                onChange={handleGstChange}
              />
              <button
                onClick={() => {
                  setShowGstPopup(false);
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AccountPage;
