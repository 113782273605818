import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import debounce from "lodash.debounce";
import "./styles/Form.css";
import "./styles/ItemsList.css";
import "./styles/AccountAndTransactions.css";
import "./styles/Popup.css";
import logo from "../images/Logo.jpg";
import preview from "../images/preview.svg";
import editIcon from "../images/edit.svg";
import trashIcon from "../images/trash.svg";
import platform from "../images/platformIcon.svg";
import ModalWindow from "./ModalWindow";
import ChargeModal from "./ChargeModal";

// Component for the popup displaying the item data
const WeightListPopup = ({ onClose, itemData }) => {
  const handlePrint = () => {
    const printContents = document.getElementById("weight-list-popup-content");

    if (printContents) {
      const originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents.innerHTML;
      window.print();
      document.body.innerHTML = originalContents;
      // window.location.reload();
    } else {
      console.error("Popup content not found.");
    }
  };

  if (!itemData) return null;

  return (
    <div id="popup-overlay">
      <div className="weight-list-popup">
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        <button
          style={{
            background: "#185492",
            color: "#fff",
            fontSize: "14px",
            border: "none",
            padding: "10px",
            cursor: "pointer",
            boxShadow: "0px 5px 80px 0px",
          }}
          className="print-button"
          onClick={handlePrint}
        >
          Print
        </button>
        <div className="weight_list_container" id="weight-list-popup-content">
          <h2 style={{ textAlign: "center" }}>Platform Sale</h2>
          <p>
            <strong>Bill No:</strong> {itemData.billNo}
          </p>
          <p>
            <strong>Bill Date:</strong> {itemData.billDate}
          </p>
          <p>
            <strong>Party Name:</strong> {itemData.partyName} / {itemData.place}
          </p>
          <p>
            <strong>Farmer Name:</strong> {itemData.farmerName}
          </p>
          <p>
            <strong>S/o:</strong> {itemData.sOn}
          </p>
          <p>
            <strong>Place:</strong> {itemData.place}
          </p>
          <p>
            <strong>Product Name:</strong> {itemData.productName}
          </p>
          <p>
            <strong>Grade Name:</strong> {itemData.gradeName}
          </p>
          <p>
            <strong>LOT NO:</strong> {itemData.lotNo}
          </p>
          <p>
            <strong>Quality Mark:</strong> {itemData.qualityMark}
          </p>
          <p>
            <strong>Bag Rate:</strong> {itemData.bagRate}
          </p>
          <p>
            <strong>Gross Weight:</strong> {itemData.grossWeight}
          </p>
          <p>
            <strong>Bags Weight:</strong> {itemData.bagsWeight}
          </p>
          <p>
            <strong>Net Weight:</strong> {itemData.netWeight}
          </p>
          <br />
          <p>
            <strong>Amount:</strong> Rs. {itemData.amount}
          </p>
          <p>
            <strong>Bag Amount:</strong> Rs. {itemData.bagAmount}
          </p>
          <p>
            <strong>Total:</strong> Rs. {itemData.total}
          </p>
          <table
            style={{
              borderTop: "2px dashed #202020",
              borderBottom: "2px dashed #202020",
              borderCollapse: "collapse",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <thead>
              <tr>
                <th scope="col">S.NO</th>
                <th scope="col">Quantity(Kg)</th>
              </tr>
            </thead>
            <tbody
              style={{
                borderTop: "2px dashed #202020",
                borderBottom: "2px dashed #202020",
                textAlign: "center",
              }}
            >
              {itemData.serialNumbers.map((serial, index) => (
                <tr key={index}>
                  <td scope="row">{serial}</td>
                  <td>{itemData.weights[index]}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <th scope="row">Gross Wgt:</th>
                <th scope="row" className="total-Qty">
                  {itemData.grossWeight}
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
};

function PlatformSale() {
  // State declarations
  const [showPopup, setShowPopup] = useState(false);
  const [selectedItemData, setSelectedItemData] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [farmers, setFarmers] = useState([]); // Now holds full farmer objects
  const [farmerSuggestions, setFarmerSuggestions] = useState([]);
  const [billNo, setBillNo] = useState(
    localStorage.getItem("billNo") || "PLS-1"
  );
  const [billDate, setBillDate] = useState(() => {
    const currentDate = new Date();
    return `${String(currentDate.getDate()).padStart(2, "0")}-${String(
      currentDate.getMonth() + 1
    ).padStart(2, "0")}-${currentDate.getFullYear()}`;
  });
  const [partyName, setPartyName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [serialCount, setSerialCount] = useState(0);
  const [weightInput, setWeightInput] = useState("0.000");
  const [tareWeight, setTareWeight] = useState(
    localStorage.getItem("tareWeight") || "0.00"
  );
  const [netWeight, setNetWeight] = useState(
    localStorage.getItem("netWeight") || "0.00"
  );
  const [bags, setBags] = useState("");
  const [rate, setRate] = useState("");
  const [amount, setAmount] = useState("0.00");
  const [coolieCommission, setCoolieCommission] = useState("0.00");
  const [commission, setCommission] = useState("0.00");
  const [gAmt, setGAmt] = useState("25");
  const [netAmt, setNetAmt] = useState("0.00");
  const [fspNo, setFspNo] = useState("");
  const [transactionItems2, setTransactionItems2] = useState([]);
  const [itemName, setItemName] = useState("");
  const [itemSuggestions, setItemSuggestions] = useState([]);
  const [mark, setMark] = useState("");
  const [dropdown, setDropdown] = useState("");
  const [town, setTown] = useState("");
  const [farmer, setFarmer] = useState("");
  const [editIndex, setEditIndex] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [partyNameValid, setPartyNameValid] = useState(true);
  const [itemNameValid, setItemNameValid] = useState(true);
  const [gAmtMultiplier, setGAmtMultiplier] = useState(25);
  const [transactionTypes, setTransactionTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [chargeModalOpen, setChargeModalOpen] = useState(false);
  const [charges3, setCharges3] = useState([
    {
      id: 1,
      chargeName: "TCS P",
      rate: "0.100",
      value: "0.000",
      adjAcName: "TCS Purchase A/C",
      gst: "0",
      gstAmt: "0.00",
      total: "0.000",
    },
    {
      id: 2,
      chargeName: "Round off",
      rate: "1.000",
      value: "0.000",
      adjAcName: "ROUND OFF",
      gst: "0",
      gstAmt: "0.00",
      total: "0.000",
    },
  ]);
  const [buttonText, setButtonText] = useState("Save & New");
  const [weightEditable, setWeightEditable] = useState(false);

  const [modalData, setModalData] = useState(
    JSON.parse(localStorage.getItem("platformSaleModalData")) || {
      weights: [],
      startFrom: 1,
    }
  );

  // State variables for Grade Name functionality
  const [gradeOptions, setGradeOptions] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState("");
  const [itemId, setItemId] = useState("");

  const navigate = useNavigate();
  const partyNameRef = useRef(null);
  const itemNameRef = useRef(null);
  const markRef = useRef(null);
  const bagsRef = useRef(null);
  const weightRef = useRef(null);
  const rateRef = useRef(null);
  const dropdownRef = useRef(null);
  const townRef = useRef(null);

  // Create the Axios instance inside the component using useMemo
  const api = useMemo(() => {
    const baseURL1 = localStorage.getItem("baseURL");
    const instance = axios.create({
      baseURL: baseURL1,
    });

    // Add request interceptor to include token in API requests
    instance.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem("token");
        if (token) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    return instance;
  }, []);

  // Function to check if the party name already exists for the current date
  const checkForExistingData = (partyName, billDate) => {
    const existingPlatformSaleData =
      JSON.parse(localStorage.getItem("PlatformSale")) || [];
    return existingPlatformSaleData.find(
      (item) => item.partyName === partyName && item.billDate === billDate
    );
  };

  const handleShowPopup = (item) => {
    const weights = item.modalData?.weights || [];
    const serialNumbers = weights.map(
      (_, index) => parseInt(item.modalData?.startFrom || 1) + index
    );

    // Find the farmer object based on farmer name
    const farmerObj = farmers.find((farmer) => farmer.act_Name === item.farmer);
    const sOnValue = farmerObj ? farmerObj.sOn || "N/A" : "N/A";

    setSelectedItemData({
      billNo: item.billNo,
      billDate: item.billDate,
      partyName: item.partyName,
      farmerName: item.farmer,
      sOn: sOnValue, // Dynamically set from farmer object
      place: item.town,
      productName: item.itemName,
      gradeName: item.gradeName || "N/A",
      lotNo: item.bags,
      qualityMark: item.mark,
      bagRate: item.rate,
      grossWeight: item.weight,
      bagsWeight: item.tareWeight,
      netWeight: item.netWeight,
      amount: item.amount,
      bagAmount: item.gAmt,
      total: item.netAmt,
      weights: weights,
      serialNumbers: serialNumbers,
    });
    setShowPopup(true);
  };

  const handleClosePopup = () => setShowPopup(false);

  const formatToThreeDecimalPlaces = (value) => {
    const number = parseFloat(value);
    if (isNaN(number)) return "0.000";
    return number.toFixed(3);
  };

  const generateNextBillNo = () => {
    const existingPlatformSaleData =
      JSON.parse(localStorage.getItem("PlatformSale")) || [];
    const existingTransactionItems =
      JSON.parse(localStorage.getItem("transactionItems2")) || [];

    const allBillNos = [
      ...existingPlatformSaleData.map((item) => item.billNo),
      ...existingTransactionItems.map((item) => item.billNo),
    ];

    const maxBillNo = allBillNos.reduce((max, current) => {
      const match = current.match(/^PLS-(\d+)$/);
      if (match) {
        const number = parseInt(match[1], 10);
        return number > max ? number : max;
      }
      return max;
    }, 0);

    const newBillNo = `PLS-${maxBillNo + 1}`;
    setBillNo(newBillNo);
    return newBillNo;
  };

  const handlePopupConfirm = () => {
    const existingPlatformSaleData =
      JSON.parse(localStorage.getItem("PlatformSale")) || [];
    const matchedData = existingPlatformSaleData.filter(
      (item) => item.partyName === partyName && item.billDate === billDate
    );

    const existingTransactionItems =
      JSON.parse(localStorage.getItem("transactionItems2")) || [];

    const updatedTransactionItems = [
      ...existingTransactionItems,
      ...matchedData,
    ];
    localStorage.setItem(
      "transactionItems2",
      JSON.stringify(updatedTransactionItems)
    );

    const remainingPlatformSaleData = existingPlatformSaleData.filter(
      (item) => !(item.partyName === partyName && item.billDate === billDate)
    );
    localStorage.setItem(
      "PlatformSale",
      JSON.stringify(remainingPlatformSaleData)
    );

    setTransactionItems2(updatedTransactionItems);
    setShowAlert(false);

    if (matchedData.length > 0) {
      setBillNo(matchedData[0].billNo);
    }

    setButtonText("Update");
  };

  const handleAddItem = () => {
    // Find the farmer object based on farmer name to retrieve sOn value
    const farmerObj = farmers.find((farmer) => farmer.act_Name === farmer);
    const sOnValue = farmerObj ? farmerObj.sOn || "N/A" : "N/A"; // Fallback if sOn is unavailable

    const newItem = {
      itemName,
      itemId, // Preserve itemId here
      gradeName: selectedGrade || "N/A", // Preserve selectedGrade here
      partyName,
      mark,
      bags,
      weight: weightInput,
      tareWeight,
      netWeight,
      rate,
      amount,
      commission,
      coolieCommission,
      gAmt,
      netAmt,
      fspNo,
      dropdown,
      town,
      farmer,
      sOn: sOnValue, // Adding sOn value here
      currentDate: new Date().toISOString(),
      modalData,
      billNo,
      billDate,
    };

    localStorage.setItem("tareWeight", tareWeight);
    localStorage.setItem("netWeight", netWeight);

    if (editIndex !== null) {
      const updatedItems = [...transactionItems2];
      updatedItems[editIndex] = {
        ...newItem,
        billNo: updatedItems[editIndex].billNo,
      };
      setTransactionItems2(updatedItems);
      setEditIndex(null);
    } else {
      const updatedItems = [newItem, ...transactionItems2];
      setTransactionItems2(updatedItems);
    }

    // Clear other fields but retain selectedGrade and itemId
    setBags("");
    setWeightInput("0.000");
    setRate("");
    setAmount("0.00");
    setCommission("0.00");
    setCoolieCommission("0.00");
    setGAmt(gAmtMultiplier.toString());
    setNetAmt("0.00");
    setFspNo("");
    setMark("");

    setWeightEditable(false);

    setModalData({ weights: [], startFrom: 1 });
    localStorage.removeItem("platformSaleModalData");
  };

  const handleSuggestionClick = (suggestion) => {
    setPartyName(suggestion.AccountName);
    setTown(suggestion.Town);
    setSuggestions([]);
    setPartyNameValid(true);

    const existingData = checkForExistingData(suggestion.AccountName, billDate);

    if (existingData) {
      setShowAlert(true);
      setBillNo(existingData.billNo);
    } else {
      generateNextBillNo();
    }
  };

  // Fetch all farmers when the component mounts
  useEffect(() => {
    const fetchAllFarmers = async () => {
      try {
        const response = await api.get(`/Master/GetFarmers`);
        setFarmers(response.data); // Store complete farmer objects
      } catch (error) {
        console.error("Error fetching all farmer names:", error);
      }
    };

    fetchAllFarmers();
  }, [api]);

  // Handle farmer name input change with debouncing
  const handleFarmerChange = (e) => {
    const value = e.target.value;
    setFarmer(value);

    if (value.length > 0) {
      debouncedFilterFarmers(value);
    } else {
      setFarmerSuggestions([]);
    }
  };

  // Debounced function to filter farmers locally
  const debouncedFilterFarmers = useCallback(
    debounce((query) => {
      const filteredSuggestions = farmers.filter((farmer) =>
        farmer.act_Name.toLowerCase().includes(query.toLowerCase())
      );
      setFarmerSuggestions(filteredSuggestions);
    }, 300),
    [farmers]
  );

  const handleFarmerSuggestionClick = (suggestion) => {
    setFarmer(`${suggestion.act_Name} - ${suggestion.act_Town}`);
    setFarmerSuggestions([]); // Clear the suggestions list
  };

  const handleModalClose = (data) => {
    if (data) {
      const validatedWeights = data.weights
        .filter((weight) => weight && !isNaN(parseFloat(weight)))
        .map((weight) => parseFloat(weight).toFixed(3));

      const serialNumbers = validatedWeights.map(
        (_, index) => data.startFrom + index
      );

      const updatedModalData = {
        weights: validatedWeights,
        serials: serialNumbers,
        startFrom: data.startFrom,
      };
      setModalData(updatedModalData);
      localStorage.setItem(
        "platformSaleModalData",
        JSON.stringify(updatedModalData)
      );

      const totalWeight = validatedWeights.reduce(
        (acc, weight) => acc + parseFloat(weight),
        0
      );
      setWeightInput(totalWeight.toFixed(3));

      const tareWeightValue = parseFloat(data.tareWeight) || 0.0;
      const netWeightValue = parseFloat(data.netWeight) || 0.0;

      setTareWeight(tareWeightValue.toFixed(3));
      setNetWeight(netWeightValue.toFixed(3));

      calculateAmount(rate, totalWeight);
      setWeightEditable(false);
    } else {
      setWeightInput("0.000");
      setWeightEditable(true);
    }
    setShowModal(false);
  };

  const handleWeightChange = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, "");
    setWeightInput(value);
  };

  const handleRateChange = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, "");
    setRate(value);
    calculateAmount(value, weightInput);
  };

  const handleBagsChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    setBags(value);
    const gAmtValue = value
      ? (parseInt(value) * gAmtMultiplier).toString()
      : gAmtMultiplier.toString();
    setGAmt(gAmtValue);
    calculateNetAmount(amount, gAmtValue);
  };

  const handleBagsBlur = () => {
    if (bags && parseInt(bags, 10) > 0) {
      setSerialCount(parseInt(bags, 10));
      setShowModal(true);
    }
  };

  const formatToTwoDecimalPlaces = (value) => {
    const number = parseFloat(value);
    if (isNaN(number)) return "";
    return number.toFixed(2);
  };

  const handleCommissionChange = (e) => {
    let value = e.target.value.replace(/[^0-9.]/g, "");

    if (/^\d*\.?\d{0,2}$/.test(value)) {
      setCommission(value);
    } else {
      setCommission(formatToTwoDecimalPlaces(value));
    }
  };

  const handleCommissionBlur = () => {
    setCommission(formatToTwoDecimalPlaces(commission));
  };

  const handleCoolieCommissionChange = (e) => {
    let value = e.target.value.replace(/[^0-9.]/g, "");

    if (/^\d*\.?\d{0,2}$/.test(value)) {
      setCoolieCommission(value);
    } else {
      setCoolieCommission(formatToTwoDecimalPlaces(value));
    }
  };

  const handleCoolieCommissionBlur = () => {
    setCoolieCommission(formatToTwoDecimalPlaces(coolieCommission));
  };

  const calculateAmount = (rate, weight) => {
    const rateValue = parseFloat(rate) || 0;
    const weightValue = parseFloat(weight) || 0;
    const amountValue = ((rateValue * weightValue) / 100).toFixed(2);
    setAmount(amountValue);
    calculateNetAmount(amountValue, gAmt);
  };

  const calculateNetAmount = (amountValue, gAmtValue) => {
    const netAmount = (parseFloat(amountValue) + parseFloat(gAmtValue)).toFixed(
      2
    );
    setNetAmt(netAmount);
  };

  const isFormValid = () => {
    return (
      partyName.trim() &&
      itemName.trim() &&
      mark.trim() &&
      bags.trim() &&
      rate.trim() &&
      partyNameValid &&
      itemNameValid
    );
  };

  const resetFields = () => {
    setItemName("");
    setItemId("");
    setSelectedGrade("");
    setGradeOptions([]);
    setPartyName("");
    setMark("");
    setTown("");
    setBags("");
    setWeightInput("0.000");
    setRate("");
    setAmount("0.00");
    setCommission("0.00");
    setCoolieCommission("0.00");
    setGAmt(gAmtMultiplier.toString());
    setNetAmt("0.00");
    setFspNo("");
    setSerialCount(0);
    setDropdown(transactionTypes.length > 0 ? transactionTypes[0].ttId : "");
    setFarmer("");
    setItemNameValid(true);
    setPartyNameValid(true);
    setModalData({});
    setWeightEditable(false);
  };

  const handleEdit = (index) => {
    const item = transactionItems2[index];
    setItemName(item.itemName || "");
    setItemId(item.itemId || "");
    setSelectedGrade(item.gradeName || "");
    setPartyName(item.partyName || "");
    setMark(item.mark || "");
    setBags(item.bags || "");
    setWeightInput(item.weight || "0.000");
    setTareWeight(item.tareWeight || "0.00");
    setNetWeight(item.netWeight || "0.00");
    setRate(item.rate || "");
    setAmount(item.amount || "0.00");
    setCommission(item.commission || "0.00");
    setCoolieCommission(item.coolieCommission || "0.00");
    setGAmt(item.gAmt || gAmtMultiplier.toString());
    setNetAmt(item.netAmt || "0.00");
    setFspNo(item.fspNo || "");
    setSerialCount(item.bags || 0);
    setDropdown(item.dropdown || transactionTypes[0]?.ttId || "");
    setTown(item.town || "");
    setFarmer(item.farmer || "");
    setModalData(item.modalData || {});
    setEditIndex(index);
    setWeightEditable(item.modalData.weights.length === 0);

    setBillNo(item.billNo);
  };

  const handleDelete = (index) => {
    const updatedItems = transactionItems2.filter((_, i) => i !== index);
    setTransactionItems2(updatedItems);
  };

  const handlePartyNameChange = (e) => {
    const value = e.target.value;
    setPartyName(value);

    const matchesSuggestion = suggestions.some(
      (suggestion) => suggestion.AccountName === value
    );
    setPartyNameValid(matchesSuggestion || value.length === 0);

    if (value.length >= 3) {
      fetchPartyNameSuggestions(value);
    } else {
      setSuggestions([]);
    }
  };

  const handleItemNameChange = (e) => {
    const value = e.target.value;
    setItemName(value);

    fetchItemNameSuggestions(value);

    const filteredSuggestions = itemSuggestions.filter((suggestion) =>
      suggestion.itemName.toLowerCase().includes(value.toLowerCase())
    );

    const matchesSuggestion = filteredSuggestions.some(
      (suggestion) => suggestion.itemName === value
    );

    if (matchesSuggestion) {
      const matchedItem = filteredSuggestions.find(
        (suggestion) => suggestion.itemName === value
      );
      setGAmt(matchedItem.gAmt);
      setItemId(matchedItem.itemId);
    } else {
      setGAmt("0");
      setItemId("");
    }

    setItemNameValid(matchesSuggestion || value.length === 0);
  };

  const handleItemNameFocus = () => {
    fetchItemNameSuggestions("");
  };

  const handlePartyNameBlur = () => {
    if (suggestions.length > 0) {
      const isPartyValid = suggestions.some(
        (suggestion) => suggestion.AccountName === partyName
      );
      setPartyNameValid(isPartyValid || partyName.length === 0);
    } else {
      setPartyNameValid(true);
    }
  };

  const handleItemNameBlur = () => {
    setItemNameValid(
      itemSuggestions.some((suggestion) => suggestion.itemName === itemName) ||
        itemName.length === 0
    );
  };

  const fetchItemNameSuggestions = async (query) => {
    try {
      const response = await api.get(`/Master/ListofItems?itemName=${query}`);
      const mappedSuggestions = response.data.map((suggestion) => ({
        itemName: suggestion.itemName,
        gAmt: suggestion.gunnyAmt.toString(),
        itemId: suggestion.itemid || suggestion.itemId || suggestion.id,
      }));
      setItemSuggestions(mappedSuggestions);

      if (mappedSuggestions.length > 0) {
        setGAmt(mappedSuggestions[0].gAmt);
        setItemId(mappedSuggestions[0].itemId);
      }
    } catch (error) {
      console.error("Error fetching item name suggestions", error);
      setItemSuggestions([]);
    }
  };

  const fetchPartyNameSuggestions = async (query) => {
    try {
      const response = await api.get(`/Master/Accounts?AccountName=${query}`);
      const mappedSuggestions = response.data.map((suggestion) => ({
        AccountName: suggestion.act_Name,
        Town: suggestion.act_Town,
      }));
      setSuggestions(mappedSuggestions);
    } catch (error) {
      console.error("Error fetching party name suggestions", error);
      setSuggestions([]);
    }
  };

  const fetchCommissionAndCoolie = async () => {
    try {
      const response = await api.get(`/Login/GetCommissionAndCooli`);
      if (response.data && response.data.length > 0) {
        const { commission, cooli } = response.data[0];
        setCommission(commission.toFixed(2));
        setCoolieCommission(cooli.toFixed(2));
      }
    } catch (error) {
      console.error("Error fetching commission and coolie commission", error);
      setCommission("0.00");
      setCoolieCommission("0.00");
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSaveAndUpdate = () => {
    if (buttonText === "Update") {
      const existingPlatformSaleData =
        JSON.parse(localStorage.getItem("PlatformSale")) || [];
      const updatedPlatformSaleData = [
        ...existingPlatformSaleData,
        ...transactionItems2,
      ];
      localStorage.setItem(
        "PlatformSale",
        JSON.stringify(updatedPlatformSaleData)
      );
      localStorage.removeItem("transactionItems2");
      setTransactionItems2([]);
      setButtonText("Save & Clear");

      generateNextBillNo();

      resetFields();
    } else {
      handleSaveAndNew();
    }
  };

  const handleSaveAndNew = async () => {
    const currentDate = new Date().toISOString();
    const itemsWithDate = transactionItems2.map((item) => ({
      ...item,
      currentDate,
    }));

    try {
      const existingPlatformSaleData =
        JSON.parse(localStorage.getItem("PlatformSale")) || [];

      const updatedPlatformSaleData = [
        ...existingPlatformSaleData,
        ...itemsWithDate,
      ];

      localStorage.setItem(
        "PlatformSale",
        JSON.stringify(updatedPlatformSaleData)
      );

      setTransactionItems2([]);

      resetFields();

      localStorage.removeItem("platformSaleModalData");

      generateNextBillNo();
    } catch (error) {
      console.error("Failed to save transaction data", error);
    }
  };

  // Fetch Grade Options based on itemId
  useEffect(() => {
    if (itemId) {
      fetchGradeOptions(itemId);
    } else {
      setGradeOptions([]);
      setSelectedGrade("");
    }
  }, [itemId]);

  const fetchGradeOptions = async (itemId) => {
    console.log("Fetching grades for Item ID:", itemId);
    try {
      const response = await api.get(`/Master/GetCommodities?itemid=${itemId}`);
      console.log("Grade API Response:", response.data);

      if (Array.isArray(response.data)) {
        const grades = response.data.map((grade) => ({
          gradeId: grade.cmdTypeID,
          gradeName: grade.cmdDesc,
        }));
        setGradeOptions(grades);
        console.log("Mapped Grade Options:", grades);
      } else {
        console.error("Unexpected API response structure:", response.data);
        setGradeOptions([]);
      }
    } catch (error) {
      console.error("Error fetching grade options:", error);
      setGradeOptions([]);
    }
  };

  useEffect(() => {
    const fetchGAmtMultiplier = async () => {
      try {
        const response = await api.get("/api/g-amt-multiplier");
        setGAmtMultiplier(response.data.multiplier);
      } catch (error) {
        console.error("Error fetching G Amt Multiplier", error);
      }
    };

    const fetchTransactionTypes = async () => {
      try {
        const response = await api.get(`/Master/Ttypes`);
        setTransactionTypes(response.data);
        setDropdown(response.data.length > 0 ? response.data[0].ttId : "");
      } catch (error) {
        console.error("Error fetching transaction types", error);
      } finally {
        setLoading(false);
      }
    };

    fetchGAmtMultiplier();
    fetchTransactionTypes();
    fetchCommissionAndCoolie();

    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }

    const savedCharges = localStorage.getItem("charges3");
    if (savedCharges) {
      setCharges3(JSON.parse(savedCharges));
    }

    const savedItems = localStorage.getItem("transactionItems2");
    if (savedItems) {
      setTransactionItems2(JSON.parse(savedItems));
    }

    fetchItemNameSuggestions("");
  }, [navigate, api]);

  useEffect(() => {
    localStorage.setItem(
      "transactionItems2",
      JSON.stringify(transactionItems2)
    );
    localStorage.setItem("billNo", billNo);
  }, [transactionItems2, billNo]);

  const filteredItems = transactionItems2.filter((item) =>
    item.farmer.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const prevItemDetails = {};

  const renderTableRow = (item, index, prevItemDetails) => {
    const shouldRenderDetails =
      !prevItemDetails.itemName ||
      prevItemDetails.itemName !== item.itemName ||
      prevItemDetails.farmer !== item.farmer ||
      prevItemDetails.mark !== item.mark;

    if (shouldRenderDetails) {
      prevItemDetails.itemName = item.itemName;
      prevItemDetails.farmer = item.farmer;
      prevItemDetails.mark = item.mark;
    }

    return (
      <React.Fragment
        key={`${item.itemName}-${item.farmer}-${item.mark}-${index}`}
      >
        {shouldRenderDetails && (
          <tr className="trHeader">
            <td colSpan={8}>
              {item.itemName} - {item.farmer}
            </td>
            <td colSpan={5} className="mark">
              {item.mark}
            </td>
          </tr>
        )}
        <tr>
          <td>{item.bags}</td>
          <td>{item.weight}</td>
          <td>{item.rate}</td>
          <td>{item.gAmt}</td>
          <td>{item.netAmt}</td>
          <td>{item.fspNo}</td>
          <td>
            <img
              id="itemImg"
              src={preview}
              alt="Preview"
              style={{ cursor: "pointer" }}
              onClick={() => handleShowPopup(item)}
            />
          </td>
          <td>
            <img
              src={editIcon}
              alt="Edit"
              onClick={() => handleEdit(index)}
              style={{ cursor: "pointer" }}
            />
          </td>
          <td>
            <img
              src={trashIcon}
              alt="Delete"
              onClick={() => handleDelete(index)}
              style={{ cursor: "pointer" }}
            />
          </td>
        </tr>
      </React.Fragment>
    );
  };

  return (
    <>
      <header className="main-header">
        <NavLink to="/">
          <img src={logo} alt="Kliq Soft Logo" className="logo" />
        </NavLink>
      </header>
      <main>
        <div className="transactions">
          <p>Transactions</p>
          <p>
            <img src={platform} alt="Platform" />
            Platform Sale
          </p>
        </div>

        <div className="goback">
          <NavLink to="/">
            <svg
              fill="#185492"
              className="left-arrow"
              xmlns="http://www.w3.org/2000/svg"
              width={22}
              height={22}
              viewBox="0 0 20 20"
            >
              <path d="M10,0A10,10,0,1,0,20,10,10,10,0,0,0,10,0Zm6,11H7.38l2.376,2.375L8.342,14.789,3.607,10.055,8.342,5.322,9.756,6.736,7.49,9H16Z" />
            </svg>
          </NavLink>
        </div>

        <div className="form-container clear">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <form className="form form1">
              <div className="input-box active-grey focus">
                <label className="input-label">Transaction Type</label>
                <select
                  className="input-1"
                  value={dropdown}
                  ref={dropdownRef}
                  onChange={(e) => setDropdown(e.target.value)}
                >
                  {transactionTypes.map((type) => (
                    <option key={type.ttId} value={type.ttId}>
                      {type.ttName}
                    </option>
                  ))}
                </select>
              </div>

              <div className="row row-bill-info">
                <div className="input-box col focus">
                  <label className="input-label">Bill No.</label>
                  <input
                    type="text"
                    className="input-1"
                    value={billNo}
                    readOnly
                  />
                </div>
                <div className="input-box col focus">
                  <label className="input-label">Bill Date</label>
                  <input
                    type="text"
                    className="input-1"
                    value={billDate}
                    readOnly
                  />
                </div>
              </div>

              <div className="row">
                <div className="input-box col focus">
                  <label
                    className={`input-label ${
                      !partyNameValid ? "label-invalid" : ""
                    }`}
                  >
                    Party Name *
                  </label>
                  <input
                    type="text"
                    id="inputField"
                    className={`input-1 ${
                      !partyNameValid ? "input-invalid" : ""
                    }`}
                    ref={partyNameRef}
                    required
                    value={partyName || ""}
                    onChange={handlePartyNameChange}
                    onBlur={handlePartyNameBlur}
                  />
                  {suggestions.length > 0 && (
                    <ul className="suggestions-list">
                      {suggestions.map((suggestion, index) => (
                        <li
                          key={index}
                          onClick={() => handleSuggestionClick(suggestion)}
                        >
                          {suggestion.AccountName}
                        </li>
                      ))}
                    </ul>
                  )}
                  {!partyNameValid && (
                    <div className="invalid-feedback">
                      Party name does not exist.{" "}
                      <NavLink to="/accountpage" className="add-new">
                        Add New Account
                      </NavLink>
                    </div>
                  )}
                </div>
                <div className="input-box col focus">
                  <label className="input-label">Town</label>
                  <input
                    type="text"
                    className="input-1"
                    ref={townRef}
                    value={town || ""}
                    onChange={(e) => setTown(e.target.value)}
                  />
                </div>
              </div>

              <div className="row row1">
                <div className="input-box col col1 focus">
                  <label className="input-label">Item Name *</label>
                  <select
                    className={`input-1 ${
                      !itemNameValid ? "input-invalid" : ""
                    }`}
                    ref={itemNameRef}
                    value={itemName || ""}
                    onChange={handleItemNameChange}
                    onFocus={handleItemNameFocus}
                    onBlur={handleItemNameBlur}
                  >
                    <option value="">Select an item</option>
                    {itemSuggestions.map((suggestion, index) => (
                      <option
                        key={suggestion.itemId || index}
                        value={suggestion.itemName}
                      >
                        {suggestion.itemName}
                      </option>
                    ))}
                  </select>
                  {!itemNameValid && (
                    <div className="invalid-feedback">
                      Item name does not exist.
                    </div>
                  )}
                </div>

                {/* Grade Name Dropdown */}
                <div className="input-box col col2 focus">
                  <label className="input-label">Grade Name</label>
                  <select
                    className="input-1"
                    value={selectedGrade}
                    onChange={(e) => setSelectedGrade(e.target.value)}
                  >
                    <option value="">Select Grade</option>
                    {gradeOptions.map((grade) => (
                      <option key={grade.gradeId} value={grade.gradeName}>
                        {grade.gradeName}
                      </option>
                    ))}
                  </select>
                </div>
                {/* End of Grade Name Dropdown */}
              </div>
            </form>
          )}

          <form className="form form2">
            <div className="row row1">
              <div className="input-box col col1 focus">
                <label className="input-label">Farmer Name</label>
                <input
                  type="text"
                  className="input-1"
                  value={farmer}
                  onChange={handleFarmerChange}
                  placeholder="Start typing a farmer name"
                />

                {farmerSuggestions.length > 0 && (
                  <ul className="suggestions-list">
                    {farmerSuggestions.map((suggestion, index) => (
                      <li
                        key={index}
                        onClick={() => handleFarmerSuggestionClick(suggestion)}
                      >
                        {suggestion.act_Name} - {suggestion.act_Town}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="input-box col col2 focus">
                <label className="input-label">Mark *</label>
                <input
                  type="text"
                  className="input-1"
                  ref={markRef}
                  value={mark || ""}
                  onChange={(e) => setMark(e.target.value)}
                />
              </div>
            </div>
            <div className="row row2">
              <div className="input-box col col1 focus">
                <label className="input-label">Bags *</label>
                <input
                  type="text"
                  className="input-1"
                  ref={bagsRef}
                  value={bags || ""}
                  onChange={handleBagsChange}
                  inputMode="numeric"
                  onBlur={handleBagsBlur}
                />
              </div>
              <div className="input-box col col3 focus">
                <label className="input-label">Weight</label>
                <input
                  type="text"
                  className="input-1 numeric-input three-decimal"
                  ref={weightRef}
                  inputMode="decimal"
                  value={weightInput}
                  onInput={handleWeightChange}
                  onBlur={() =>
                    setWeightInput(formatToThreeDecimalPlaces(weightInput))
                  }
                  readOnly={!weightEditable}
                />
              </div>
            </div>
            <div className="row row3">
              <div className="input-box col col1 focus">
                <label className="input-label">Rate *</label>
                <input
                  type="text"
                  className="input-1 numeric-input"
                  ref={rateRef}
                  value={rate || ""}
                  inputMode="numeric"
                  onChange={handleRateChange}
                />
              </div>
              <div className="input-box col col2 focus">
                <label className="input-label">Amount</label>
                <input
                  type="text"
                  className="input-1 numeric-input"
                  value={amount}
                  readOnly
                />
              </div>
              <div className="input-box col col3 focus">
                <label className="input-label">Commission</label>
                <input
                  type="text"
                  className="input-1 numeric-input"
                  value={commission}
                  onChange={handleCommissionChange}
                  onBlur={handleCommissionBlur}
                />
              </div>
            </div>
            <div className="row row4">
              <div className="input-box col col1 focus">
                <label className="input-label">Coolie Commission</label>
                <input
                  type="text"
                  className="input-1 numeric-input"
                  value={coolieCommission}
                  onChange={handleCoolieCommissionChange}
                  onBlur={handleCoolieCommissionBlur}
                />
              </div>
              <div className="input-box col col2 focus">
                <label className="input-label">G.Amt</label>
                <input
                  type="text"
                  className="input-1 numeric-input"
                  value={gAmt}
                  readOnly
                />
              </div>
              <div className="input-box col col3 focus">
                <label className="input-label">Net Amt</label>
                <input
                  type="text"
                  className="input-1 numeric-input"
                  value={netAmt}
                  readOnly
                />
              </div>
            </div>
            <div className="row row5">
              <div className="input-box col col1 focus">
                <label className="input-label">FSP No.</label>
                <input
                  type="text"
                  className="input-1 numeric-input"
                  value={fspNo}
                  onChange={(e) => setFspNo(e.target.value)}
                />
              </div>
            </div>
            <button
              type="button"
              id="submitButton"
              className={`btn btn-primary pull-right ${
                !isFormValid() ? "btn-disabled" : ""
              }`}
              disabled={!isFormValid()}
              onClick={handleAddItem}
            >
              {editIndex !== null ? "Update Item" : "Add Item"}
            </button>
            <div className="listAndSave">
              <button
                type="button"
                className="btn btn-save"
                onClick={handleSaveAndUpdate}
              >
                {buttonText}
              </button>
            </div>
          </form>
        </div>

        <div
          className="item-search"
          style={{ marginBottom: filteredItems.length > 0 ? "0" : "3rem" }}
        >
          <input
            type="text"
            name="search"
            id="search"
            placeholder="Farmer Name"
            value={searchQuery || ""}
            onChange={handleSearchChange}
          />
          <button className="btn btn-search">View</button>
        </div>

        {filteredItems.length > 0 && (
          <div
            className="item-list"
            style={{ overflowX: "auto", marginBottom: "3.5rem" }}
          >
            <table>
              <thead>
                <tr>
                  <th scope="col">Bags</th>
                  <th scope="col">Weight</th>
                  <th scope="col">Rate</th>
                  <th scope="col">G.Amt</th>
                  <th scope="col">Net Amt</th>
                  <th scope="col">FSP No.</th>
                  <th
                    scope="col"
                    className="action"
                    colSpan={3}
                    style={{ opacity: 0 }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredItems.map((item, index) =>
                  renderTableRow(item, index, prevItemDetails)
                )}
              </tbody>
              <tfoot>
                <tr>
                  <th colSpan={9}>No. of Items: {transactionItems2.length}</th>
                </tr>
              </tfoot>
            </table>
          </div>
        )}
      </main>
      {showModal && (
        <ModalWindow
          serialCount={serialCount}
          onClose={handleModalClose}
          initialWeights={modalData.weights}
          initialSerials={modalData.serials}
          startSerial={modalData.startFrom}
        />
      )}
      {chargeModalOpen && (
        <ChargeModal
          isOpen={chargeModalOpen}
          onClose={() => setChargeModalOpen(false)}
          chargesData={charges3}
          onSave={(data) => setCharges3(data)}
        />
      )}
      {showAlert && (
        <>
          <div
            className="alert-popup-overlay"
            onClick={() => setShowAlert(false)}
          ></div>
          <div className="alert-popup">
            <p>Party Name already exists on this day.</p>
            <button onClick={handlePopupConfirm}>OK</button>
          </div>
        </>
      )}
      {showPopup && (
        <WeightListPopup
          onClose={handleClosePopup}
          itemData={selectedItemData}
        />
      )}
    </>
  );
}

export default PlatformSale;
