import React, { useState, useEffect } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import "./App.css";
import Dashboard from "./components/Dashboard";
import PlatformSale from "./components/PlatformSale";
import AcSale from "./components/AcSale";
import Transactions from "./components/Transactions";
import AccountPage from "./components/Account";
import ListPage from "./components/ListPage";
import PurchaseReport from "./components/DailyReport";
import MarkWiseReport from "./components/MarkWiseReport";
import SalesReport from "./components/SalesReport";
import Login from "./components/Login";
import Header from "./components/Header";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const logoutTimeout = 300000; // 5 minutes

  useEffect(() => {
    const fetchAuthStatus = async () => {
      try {
        const token = localStorage.getItem("token");
        if (token) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } catch (err) {
        setError("Failed to check authentication status");
      } finally {
        setIsLoading(false);
      }
    };
    fetchAuthStatus();
  }, []);

  // Log changes in authentication state
  useEffect(() => {
    console.log("isAuthenticated state:", isAuthenticated);
  }, [isAuthenticated]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
    navigate("/login"); // Redirect to login page after logout
  };

  // Automatic logout after 5 minutes of inactivity
  useEffect(() => {
    let timeoutId;

    const resetTimer = () => {
      if (isAuthenticated) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(handleLogout, logoutTimeout);
      }
    };

    const activityEvents = [
      "mousemove",
      "keypress",
      "click",
      "scroll",
      "touchstart",
    ];

    activityEvents.forEach((event) =>
      window.addEventListener(event, resetTimer)
    );

    resetTimer(); // Initialize the timer

    return () => {
      clearTimeout(timeoutId);
      activityEvents.forEach((event) =>
        window.removeEventListener(event, resetTimer)
      );
    };
  }, [isAuthenticated]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      {isAuthenticated && <Header onLogout={handleLogout} />}
      <Routes>
        {!isAuthenticated ? (
          <>
            <Route
              path="/login"
              element={<Login setIsAuthenticated={setIsAuthenticated} />}
            />
            <Route path="*" element={<Navigate to="/login" replace />} />
          </>
        ) : (
          <>
            <Route path="/" element={<Dashboard />} />
            <Route path="/transactions" element={<Transactions />} />
            <Route path="/accountpage" element={<AccountPage />} />
            <Route path="/listpage" element={<ListPage />} />
            <Route path="/MarkWiseReport" element={<MarkWiseReport />} />
            <Route path="/SalesReport" element={<SalesReport />} />
            <Route path="/acsale" element={<AcSale />} />
            <Route path="/PlatformSale" element={<PlatformSale />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </>
        )}
      </Routes>
    </div>
  );
}

export default App;
