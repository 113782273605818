import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import logo from "../images/Logo.jpg";
import whatsApp from "../images/whatsapp.svg";
import "./styles/Admin.css";

function Login({ setIsAuthenticated }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [companies, setCompanies] = useState([]);
  const [financialYears, setFinancialYears] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedFinancialYear, setSelectedFinancialYear] = useState("");
  const [isAuthenticatedLocal, setIsAuthenticatedLocal] = useState(false);
  const navigate = useNavigate();

  const baseURL = "https://kliqss.in/API";

  const authenticateUser = async () => {
    try {
      const response = await axios.post(`${baseURL}/Authentication`, {
        username,
        password,
      });

      const { token, roleId, roleName, userId, userName } = response.data;

      if (userId) {
        localStorage.setItem("token", token);
        localStorage.setItem("roleId", roleId);
        localStorage.setItem("roleName", roleName);
        localStorage.setItem("userId", userId);
        localStorage.setItem("userName", userName);

        setIsAuthenticatedLocal(true);
        fetchCompanies(token);
      } else {
        alert("Invalid credentials");
      }
    } catch (error) {
      console.error("Error checking authentication:", error);
      alert("Error checking authentication");
    }
  };

  const fetchCompanies = async (token) => {
    try {
      const companiesResponse = await axios.get(`${baseURL}/Login/Company`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setCompanies(companiesResponse.data);

      // Select the first company by default
      if (companiesResponse.data.length > 0) {
        const firstCompany = companiesResponse.data[0];
        setSelectedCompany(firstCompany.compID);
        fetchFinancialYears(token, firstCompany.compID); // Fetch financial years for the selected company
      }
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  const fetchFinancialYears = async (token, companyId) => {
    try {
      console.log("Fetching financial years for companyId:", companyId);
      const financialYearsResponse = await axios.get(
        `${baseURL}/Login/FY/?CompID=${companyId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setFinancialYears(financialYearsResponse.data);

      // Select the latest financial year by default
      if (financialYearsResponse.data.length > 0) {
        const latestFinancialYear = financialYearsResponse.data[0];
        setSelectedFinancialYear(latestFinancialYear.fyid);
      }
    } catch (error) {
      console.error("Error fetching financial years:", error);
      alert(
        "Unable to fetch financial years. Please check the endpoint and try again."
      );
    }
  };

  const handleCompanyChange = (e) => {
    const selectedCompId = e.target.value;
    setSelectedCompany(selectedCompId);

    const token = localStorage.getItem("token");
    if (token) {
      fetchFinancialYears(token, selectedCompId);
    }
  };

  const handleLogin = async () => {
    if (!username || !password) {
      alert("Please enter username and password.");
      return;
    }

    await authenticateUser();

    if (!isAuthenticatedLocal) return;

    if (!selectedCompany || !selectedFinancialYear) {
      alert("Please select a company and financial year.");
      return;
    }

    // Store selected company and financial year in localStorage
    localStorage.setItem("CompID", selectedCompany);
    localStorage.setItem("FinancialYearId", selectedFinancialYear);

    // Proceed to the main app
    setIsAuthenticated(true);
    navigate("/");
  };

  return (
    <div className="admin">
      <header className="login-header">
        <img src={logo} alt="logo" className="logo" />
      </header>

      <main className="login-main">
        <form id="login-form">
          <input
            type="text"
            name="username"
            id="username"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value.toLowerCase())}
            autoFocus
          />
          <input
            type="password"
            name="password"
            id="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {isAuthenticatedLocal && (
            <>
              <select
                name="companyList"
                id="companyList"
                value={selectedCompany}
                onChange={handleCompanyChange}
              >
                <option value="" disabled>
                  Select Company
                </option>
                {companies.map((company) => (
                  <option key={company.compID} value={company.compID}>
                    {company.compName}
                  </option>
                ))}
              </select>
              <select
                name="FinancialYear"
                id="FinancialYear"
                value={selectedFinancialYear}
                onChange={(e) => setSelectedFinancialYear(e.target.value)}
              >
                <option value="" disabled>
                  Select Financial Year
                </option>
                {financialYears.map((year) => (
                  <option key={year.fyid} value={year.fyid}>
                    {year.fy}
                  </option>
                ))}
              </select>
            </>
          )}
          <button type="button" onClick={handleLogin} className="btn">
            Login
          </button>
        </form>
      </main>

      <footer className="login-footer">
        <p>Developed by KLIQ Soft Solutions</p>
        <p>
          <img src={whatsApp} className="icons" alt="whatsapp icon" />
          <a href="tel:+91 9912662995">9912662995</a>
        </p>
      </footer>
    </div>
  );
}

export default Login;

// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import logo from "../images/Logo.jpg";
// import whatsApp from "../images/whatsapp.svg";
// import "./styles/Admin.css";

// const buttonStyle = {
//   background: "none",
//   border: "none",
//   cursor: "pointer",
//   position: "fixed",
//   top: "10px",
//   right: "20px",
// };

// const popupStyle = {
//   position: "fixed",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   backgroundColor: "#fff",
//   color: "#202020",
//   padding: "20px",
//   boxShadow: "0 0 10px rgba(0,0,0,0.2)",
//   zIndex: 1000,
// };

// const popupContentStyle = {
//   display: "flex",
//   flexDirection: "column",
//   gap: "10px",
//   fontSize: "14px",
// };

// const inputStyle = {
//   padding: "8px",
//   borderRadius: "4px",
//   border: "1px solid #ccc",
//   marginBottom: "0px",
//   backgroundColor: "white",
//   color: "#202020",
// };

// const saveButtonStyle = {
//   backgroundColor: "#185492",
//   color: "white",
//   padding: "5px",
//   border: "none",
//   borderRadius: "4px",
//   cursor: "pointer",
// };

// const cancelButtonStyle = {
//   backgroundColor: "#f44336",
//   color: "white",
//   padding: "5px",
//   border: "none",
//   borderRadius: "4px",
//   cursor: "pointer",
// };

// const twoButtons = {
//   display: "flex",
//   gap: "10px",
// };

// function Login({ setIsAuthenticated }) {
//   const [username, setUsername] = useState("");
//   const [password, setPassword] = useState("");
//   const [companies, setCompanies] = useState([]);
//   const [financialYears, setFinancialYears] = useState([]);
//   const [selectedCompany, setSelectedCompany] = useState("");
//   const [selectedFinancialYear, setSelectedFinancialYear] = useState("");
//   const [isAuthenticatedLocal, setIsAuthenticatedLocal] = useState(false);
//   const navigate = useNavigate();

//   const baseURL = "https://kliqss.in/API";

//   const authenticateUser = async () => {
//     try {
//       const response = await axios.post(`${baseURL}/Authentication`, {
//         username,
//         password,
//       });

//       const { token, roleId, roleName, userId, userName } = response.data;

//       if (userId) {
//         localStorage.setItem("token", token);
//         localStorage.setItem("roleId", roleId);
//         localStorage.setItem("roleName", roleName);
//         localStorage.setItem("userId", userId);
//         localStorage.setItem("userName", userName);

//         setIsAuthenticatedLocal(true);
//         fetchCompanies(token);
//       } else {
//         alert("Invalid credentials");
//       }
//     } catch (error) {
//       console.error("Error checking authentication:", error);
//       alert("Error checking authentication");
//     }
//   };

//   const fetchCompanies = async (token) => {
//     try {
//       const companiesResponse = await axios.get(`${baseURL}/Login/Company`, {
//         headers: { Authorization: `Bearer ${token}` },
//       });
//       setCompanies(companiesResponse.data);

//       // Select the first company by default
//       if (companiesResponse.data.length > 0) {
//         const firstCompany = companiesResponse.data[0];
//         setSelectedCompany(firstCompany.compID);
//         fetchFinancialYears(token, firstCompany.compID); // Fetch financial years for the selected company
//       }
//     } catch (error) {
//       console.error("Error fetching companies:", error);
//     }
//   };

//   const fetchFinancialYears = async (token, companyId) => {
//     try {
//       console.log("Fetching financial years for companyId:", companyId); // Log the companyId
//       const financialYearsResponse = await axios.get(
//         `${baseURL}/Login/FY/?CompID=${companyId}`,
//         {
//           headers: { Authorization: `Bearer ${token}` },
//         }
//       );
//       setFinancialYears(financialYearsResponse.data);

//       // Select the latest financial year by default
//       if (financialYearsResponse.data.length > 0) {
//         const latestFinancialYear = financialYearsResponse.data[0]; // Assuming the first item is the latest
//         setSelectedFinancialYear(latestFinancialYear.fyid);
//       }
//     } catch (error) {
//       console.error("Error fetching financial years:", error);
//       alert(
//         "Unable to fetch financial years. Please check the endpoint and try again."
//       );
//     }
//   };

//   const handleCompanyChange = (e) => {
//     const selectedCompId = e.target.value;
//     setSelectedCompany(selectedCompId);

//     const token = localStorage.getItem("token");
//     if (token) {
//       fetchFinancialYears(token, selectedCompId);
//     }
//   };

//   const handleLogin = async () => {
//     if (!username || !password) {
//       alert("Please enter username and password.");
//       return;
//     }

//     await authenticateUser();

//     if (!isAuthenticatedLocal) return;

//     if (!selectedCompany || !selectedFinancialYear) {
//       alert("Please select a company and financial year.");
//       return;
//     }

//     try {
//       const token = localStorage.getItem("token");
//       const response = await axios.post(`${baseURL}/Authentication`, {
//         username,
//         password,
//         companyId: selectedCompany,
//         financialYearId: selectedFinancialYear,
//       });
//       const { roleId, roleName, userId, userName } = response.data;

//       localStorage.setItem("token", token);
//       localStorage.setItem("roleId", roleId);
//       localStorage.setItem("roleName", roleName);
//       localStorage.setItem("userId", userId);
//       localStorage.setItem("userName", userName);

//       setIsAuthenticated(true);
//       navigate("/");
//     } catch (error) {
//       alert("Invalid credentials");
//     }
//   };

//   return (
//     <div className="admin">
//       <header className="login-header">
//         <img src={logo} alt="logo" className="logo" />
//       </header>

//       <main className="login-main">
//         <form id="login-form">
//           <input
//             type="text"
//             name="username"
//             id="username"
//             placeholder="Username"
//             value={username}
//             onChange={(e) => setUsername(e.target.value.toLowerCase())} // Convert to lowercase
//             autoFocus
//           />
//           <input
//             type="password"
//             name="password"
//             id="password"
//             placeholder="Password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//           />
//           {isAuthenticatedLocal && (
//             <>
//               <select
//                 name="companyList"
//                 id="companyList"
//                 value={selectedCompany}
//                 onChange={handleCompanyChange}
//               >
//                 <option value="" disabled>
//                   Select Company
//                 </option>
//                 {companies.map((company) => (
//                   <option key={company.compID} value={company.compID}>
//                     {company.compName}
//                   </option>
//                 ))}
//               </select>
//               <select
//                 name="FinancialYear"
//                 id="FinancialYear"
//                 value={selectedFinancialYear}
//                 onChange={(e) => setSelectedFinancialYear(e.target.value)}
//               >
//                 <option value="" disabled>
//                   Select Financial Year
//                 </option>
//                 {financialYears.map((year) => (
//                   <option key={year.fyid} value={year.fyid}>
//                     {year.fy}
//                   </option>
//                 ))}
//               </select>
//             </>
//           )}
//           <button type="button" onClick={handleLogin} className="btn">
//             Login
//           </button>
//         </form>
//       </main>

//       <footer className="login-footer">
//         <p>Developed by KLIQ Soft Solutions</p>
//         <p>
//           <img src={whatsApp} className="icons" alt="whatsapp icon" />
//           <a href="tel:+91 9912662995">9912662995</a>
//         </p>
//       </footer>
//     </div>
//   );
// }

// export default Login;
