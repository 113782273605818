// src/utilities/DatePicker.js
import React, { useState } from "react";

const DatePicker = ({ onDateChange }) => {
  const today = new Date();
  const year = today.getFullYear();
  const month = (today.getMonth() + 1).toString().padStart(2, "0");
  const day = today.getDate().toString().padStart(2, "0");
  const initialDate = `${year}-${month}-${day}`;

  const [selectedDate, setSelectedDate] = useState(initialDate);

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
    onDateChange(e.target.value);
  };

  return (
    <input
      type="date"
      id="datePicker"
      value={selectedDate}
      onChange={handleDateChange}
    />
  );
};

export default DatePicker;
