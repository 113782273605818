import React, { useState, useEffect, useRef, useMemo } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import "./styles/Form.css";
import "./styles/ItemsList.css";
import "./styles/AccountAndTransactions.css";
import "./styles/Popup.css";
import logo from "../images/Logo.jpg";
import preview from "../images/preview.svg";
import editIcon from "../images/edit.svg";
import trashIcon from "../images/trash.svg";
import purchase from "../images/purchase.svg";
import ModalWindow from "./ModalWindow";
import ChargeModal from "./ChargeModal";

// Component for the popup displaying the item data
const WeightListPopup = ({ onClose, itemData }) => {
  const handlePrint = () => {
    const printContent = document.getElementById(
      "weight-list-popup-content"
    ).innerHTML;

    let iframe = document.createElement("iframe");
    iframe.style.position = "fixed";
    iframe.style.right = "0";
    iframe.style.bottom = "0";
    iframe.style.width = "0";
    iframe.style.height = "0";
    iframe.style.border = "0";
    document.body.appendChild(iframe);

    let iframeDoc = iframe.contentWindow || iframe.contentDocument;
    if (iframeDoc.document) iframeDoc = iframeDoc.document;

    iframeDoc.open();
    iframeDoc.write(`
      <html>
        <head>
          <title>Print Document</title>
          <style>
            body { font-family: Arial, sans-serif; padding: 20px; }
            table { width: 100%; border-collapse: collapse; }
            th, td { border: 1px solid #000; padding: 8px; text-align: left; }
            th { background-color: #f2f2f2; }
          </style>
        </head>
        <body>
          ${printContent}
        </body>
      </html>
    `);
    iframeDoc.close();

    iframe.onload = () => {
      setTimeout(() => {
        iframe.contentWindow.focus();
        iframe.contentWindow.print();
        document.body.removeChild(iframe);
      }, 500);
    };
  };

  if (!itemData) return null;

  return (
    <div id="popup-overlay" className="popup-overlay">
      <div className="weight-list-popup">
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        <button
          style={{
            background: "#185492",
            color: "#fff",
            fontSize: "14px",
            border: "none",
            padding: "10px",
            cursor: "pointer",
            boxShadow: "0px 5px 80px 0px",
          }}
          className="print-button"
          onClick={handlePrint}
        >
          Print
        </button>
        <div className="weight_list_container" id="weight-list-popup-content">
          <h2 style={{ textAlign: "center" }}>Transaction Details</h2>
          <p>
            <strong>Bill No:</strong> {itemData.billNo}
          </p>
          <p>
            <strong>Bill Date:</strong> {itemData.billDate}
          </p>
          <p>
            <strong>Party Name:</strong> {itemData.partyName} / {itemData.place}
          </p>
          {/* <p>
            <strong>S/o:</strong> {itemData.sOn}
          </p> */}
          <p>
            <strong>Product Name:</strong> {itemData.productName}
          </p>
          <p>
            <strong>Grade Name:</strong> {itemData.gradeName}
          </p>
          <p>
            <strong>No. of Bags:</strong> {itemData.lotNo}
          </p>
          <p>
            <strong>Quality Mark:</strong> {itemData.qualityMark}
          </p>
          <p>
            <strong>Bag Rate:</strong> {itemData.bagRate}
          </p>
          <p>
            <strong>Gross Weight:</strong> {itemData.grossWeight}
          </p>
          <p>
            <strong>Bags Weight:</strong> {itemData.tareWeight}
          </p>
          <p>
            <strong>Net Weight:</strong> {itemData.netWeight}
          </p>
          <br />
          <p>
            <strong>Amount:</strong> Rs. {itemData.amount}
          </p>
          <p>
            <strong>Bag Amount:</strong> Rs. {itemData.bagAmount}
          </p>
          <p>
            <strong>Total:</strong> Rs. {itemData.total}
          </p>
          <table
            style={{
              borderTop: "2px dashed #202020",
              borderBottom: "2px dashed #202020",
              borderCollapse: "collapse",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <thead>
              <tr>
                <th scope="col">S.NO</th>
                <th scope="col">Quantity(Kg)</th>
              </tr>
            </thead>
            <tbody
              style={{
                borderTop: "2px dashed #202020",
                borderBottom: "2px dashed #202020",
                textAlign: "center",
              }}
            >
              {itemData.serialNumbers.map((serial, index) => (
                <tr key={index}>
                  <td scope="row">{serial}</td>
                  <td>{itemData.weights[index]}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <th scope="row">Gross Wgt:</th>
                <th scope="row" className="total-Qty">
                  {itemData.grossWeight}
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
};

function Transactions() {
  const [partyName, setPartyName] = useState(
    localStorage.getItem("partyName") || ""
  );
  const [showModal, setShowModal] = useState(false);
  const [serialCount, setSerialCount] = useState(0);
  const [weightInput, setWeightInput] = useState("0.000");
  const [tareWeight, setTareWeight] = useState("0.000");
  const [netWeight, setNetWeight] = useState("0.000");
  const [bags, setBags] = useState("");
  const [rate, setRate] = useState("");
  const [amount, setAmount] = useState("0.00");
  const [gAmt, setGAmt] = useState("0");
  const [netAmt, setNetAmt] = useState("0.00");
  const [showPopup, setShowPopup] = useState(false);
  const [selectedItemData, setSelectedItemData] = useState(null);
  const [transactionItems, setTransactionItems] = useState(
    JSON.parse(localStorage.getItem("transactionItems")) || []
  );
  const [itemName, setItemName] = useState(
    localStorage.getItem("itemName") || ""
  );
  const [itemSuggestions, setItemSuggestions] = useState([]);
  const [mark, setMark] = useState("");
  const [dropdown, setDropdown] = useState(
    localStorage.getItem("dropdown") || ""
  );
  const [town, setTown] = useState(localStorage.getItem("town") || "");
  const [description, setDescription] = useState("");
  const [editIndex, setEditIndex] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [partyNameValid, setPartyNameValid] = useState(true);
  const [itemNameValid, setItemNameValid] = useState(true);
  const [gAmtMultiplier, setGAmtMultiplier] = useState(25);
  const [transactionTypes, setTransactionTypes] = useState(
    JSON.parse(localStorage.getItem("transactionTypes")) || []
  );
  const [loading, setLoading] = useState(true);
  const [isSuggestionSelected, setIsSuggestionSelected] = useState(false);
  const [modalData, setModalData] = useState(
    JSON.parse(localStorage.getItem("modalData")) || {
      weights: [],
      serials: [],
      startFrom: 1,
      tareWeight: "0.000",
      netWeight: "0.000",
    }
  );
  const [billNo1, setBillNo1] = useState(
    localStorage.getItem("billNo1") || "PUR-1"
  );
  const [billDate, setBillDate] = useState(() => {
    const currentDate = new Date();
    return `${String(currentDate.getDate()).padStart(2, "0")}-${String(
      currentDate.getMonth() + 1
    ).padStart(2, "0")}-${currentDate.getFullYear()}`;
  });
  const [chargeModalOpen, setChargeModalOpen] = useState(false);
  const [charges2, setCharges2] = useState(
    JSON.parse(localStorage.getItem("charges2")) || [
      {
        id: 1,
        chargeName: "TCS P",
        rate: "0.100",
        value: "0.000",
        adjAcName: "TCS Purchase A/C",
        gst: "0",
        gstAmt: "0.00",
        total: "0.000",
      },
      {
        id: 2,
        chargeName: "Round off",
        rate: "1.000",
        value: "0.000",
        adjAcName: "ROUND OFF",
        gst: "0",
        gstAmt: "0.00",
        total: "0.000",
      },
    ]
  );
  const [showAlert, setShowAlert] = useState(false);
  const [isWeightEditable, setIsWeightEditable] = useState(false);
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);
  const [buttonLabel, setButtonLabel] = useState("Save & New");

  const [itemNameFocused, setItemNameFocused] = useState(false);

  // State variables for Grade Name functionality
  const [gradeOptions, setGradeOptions] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState("");
  const [itemId, setItemId] = useState("");

  // State variable for 'sonOf'
  const [sonOf, setSonOf] = useState("");

  const navigate = useNavigate();

  // Refs for input fields
  const partyNameRef = useRef(null);
  const itemNameRef = useRef(null);
  const markRef = useRef(null);
  const bagsRef = useRef(null);
  const weightRef = useRef(null);
  const rateRef = useRef(null);
  const dropdownRef = useRef(null);
  const townRef = useRef(null);
  const descriptionRef = useRef(null);

  // Create the Axios instance inside the component using useMemo
  // const api = useMemo(() => {
  //   const baseURL1 = localStorage.getItem("baseURL");
  //   const instance = axios.create({
  //     baseURL: baseURL1,
  //   });

  const api = useMemo(() => {
    const baseURL = "https://kliqss.in/API";
    const instance = axios.create({
      baseURL: baseURL,
    });

    // Add request interceptor to include token in API requests
    instance.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem("token");
        if (token) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    return instance;
  }, []);

  // Function to handle showing the popup with item details
  const handleShowPopup = (item) => {
    const weights = item.modalData?.weights || [];
    const serialNumbers = weights.map(
      (_, index) => parseInt(item.modalData?.startFrom || 1) + index
    );

    setSelectedItemData({
      billNo: item.billNo1,
      billDate: item.billDate,
      partyName: item.partyName,
      place: item.town,
      productName: item.itemName,
      sOn: item.sonOf || "N/A",
      gradeName: item.gradeName || "N/A",
      lotNo: item.bags,
      qualityMark: item.mark,
      bagRate: item.rate,
      grossWeight: item.weight,
      tareWeight: item.tareWeight || "0.000",
      netWeight: item.netWeight || "0.000",
      amount: item.amount,
      bagAmount: item.gAmt,
      total: item.netAmt,
      weights: weights,
      serialNumbers: serialNumbers,
    });
    setShowPopup(true);
  };

  // Function to handle closing the popup
  const handleClosePopup = () => setShowPopup(false);

  // Function to check for existing data
  const checkForExistingData = (partyName, billDate) => {
    const existingPurchaseData =
      JSON.parse(localStorage.getItem("Purchase")) || [];
    return existingPurchaseData.find(
      (item) => item.partyName === partyName && item.billDate === billDate
    );
  };

  // Function to generate the next Bill No.
  const generateNextBillNo = () => {
    const existingPurchaseData =
      JSON.parse(localStorage.getItem("Purchase")) || [];
    const existingTransactionItems =
      JSON.parse(localStorage.getItem("transactionItems")) || [];

    const allBillNos = [
      ...existingPurchaseData.map((item) => item.billNo1),
      ...existingTransactionItems.map((item) => item.billNo1),
    ];

    const maxBillNo = allBillNos.reduce((max, current) => {
      const match = current.match(/^PUR-(\d+)$/);
      if (match) {
        const number = parseInt(match[1], 10);
        return number > max ? number : max;
      }
      return max;
    }, 0);

    const newBillNo = `PUR-${maxBillNo + 1}`;
    setBillNo1(newBillNo);
    return newBillNo;
  };

  // useEffect to fetch initial data
  useEffect(() => {
    const fetchGAmtMultiplier = async () => {
      try {
        const response = await api.get(`/api/g-amt-multiplier`);
        setGAmtMultiplier(response.data.multiplier);
      } catch (error) {
        console.error("Error fetching G_AMT_MULTIPLIER", error);
      }
    };

    const fetchTransactionTypes = async () => {
      try {
        // const response = await api.get(`/Master/Ttypes`);
        const CompID = localStorage.getItem("CompID");
        const response = await api.get(`/Master/Ttypes?CompID=${CompID}`);
        setTransactionTypes(response.data);
        setDropdown(response.data.length > 0 ? response.data[0].ttId : "");
        localStorage.setItem("transactionTypes", JSON.stringify(response.data));
        localStorage.setItem("dropdown", response.data[0]?.ttId || "");
      } catch (error) {
        console.error("Error fetching transaction types", error);
      } finally {
        setLoading(false);
      }
    };

    fetchGAmtMultiplier();
    fetchTransactionTypes();

    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }

    const savedCharges = localStorage.getItem("charges2");
    if (savedCharges) {
      setCharges2(JSON.parse(savedCharges));
    }

    const savedItems = localStorage.getItem("transactionItems");
    if (savedItems) {
      setTransactionItems(JSON.parse(savedItems));
    }

    generateNextBillNo();
  }, [navigate, api]);

  // useEffect to update localStorage whenever transactionItems change
  useEffect(() => {
    localStorage.setItem("transactionItems", JSON.stringify(transactionItems));
  }, [transactionItems]);

  // Handler for Party Name change
  const handlePartyNameChange = (e) => {
    const value = e.target.value;
    setPartyName(value);
    localStorage.setItem("partyName", value);

    if (value.length >= 3) {
      fetchPartyNameSuggestions(value);
      setIsSuggestionSelected(false);
    } else {
      setSuggestions([]);
    }
    setPartyNameValid(true);
  };

  // Handler for Party Name blur
  const handlePartyNameBlur = () => {
    if (!isSuggestionSelected) {
      if (
        !suggestions.find((suggestion) => suggestion.AccountName === partyName)
      ) {
        setPartyNameValid(false);
      }
    }
  };

  // Handler for suggestion click (Party or Item)
  const handleSuggestionClick = (suggestion, type) => {
    if (type === "party") {
      setPartyName(suggestion.AccountName);
      localStorage.setItem("partyName", suggestion.AccountName);
      setTown(suggestion.Town);
      localStorage.setItem("town", suggestion.Town);
      setSuggestions([]);
      setPartyNameValid(true);
      setIsSuggestionSelected(true);

      // Set the 'sonOf' value
      setSonOf(suggestion.sonOf || "");

      const existingData = checkForExistingData(
        suggestion.AccountName,
        billDate
      );

      if (existingData) {
        setBillNo1(existingData.billNo1);
        setShowAlert(true);
      } else {
        generateNextBillNo();
      }
    } else if (type === "item") {
      setItemName(suggestion.itemName);
      setItemId(suggestion.itemId); // Set the selected item's ID
      localStorage.setItem("itemName", suggestion.itemName);
      setGAmt(suggestion.gAmt);
      setItemSuggestions([]);
      setItemNameValid(true);
      setIsSuggestionSelected(true);
    }
  };

  // Handler for confirming the alert popup
  const handlePopupConfirm = () => {
    const existingPurchaseData =
      JSON.parse(localStorage.getItem("Purchase")) || [];
    const matchedData = existingPurchaseData.filter(
      (item) => item.partyName === partyName && item.billDate === billDate
    );

    const existingTransactionItems =
      JSON.parse(localStorage.getItem("transactionItems")) || [];
    const updatedTransactionItems = [
      ...existingTransactionItems,
      ...matchedData,
    ];
    setTransactionItems(updatedTransactionItems);
    localStorage.setItem(
      "transactionItems",
      JSON.stringify(updatedTransactionItems)
    );

    const remainingPurchaseData = existingPurchaseData.filter(
      (item) => !(item.partyName === partyName && item.billDate === billDate)
    );
    localStorage.setItem("Purchase", JSON.stringify(remainingPurchaseData));

    if (matchedData.length > 0) {
      setBillNo1(matchedData[0].billNo1);
    }

    setButtonLabel("Update");
    setShowAlert(false);
  };

  // Handler for closing the modal
  const handleModalClose = (data) => {
    if (data && data.weights.length > 0) {
      const validWeights = data.weights.filter(
        (weight) => weight && weight.trim() !== ""
      );

      const serialNumbers = validWeights.map(
        (_, index) => parseInt(data.startFrom || 1) + index
      );

      const grossWeight = validWeights.reduce((acc, weight) => {
        const sanitizedWeight = parseFloat(weight.replace(/[^0-9.]/g, ""));
        return acc + (isNaN(sanitizedWeight) ? 0 : sanitizedWeight);
      }, 0);

      const tareWeightValue = parseFloat(data.tareWeight) || 0;
      const netWeightValue = grossWeight - tareWeightValue;

      setModalData({
        weights: validWeights,
        serials: serialNumbers,
        startFrom: data.startFrom,
        tareWeight: tareWeightValue.toFixed(3),
        netWeight: netWeightValue.toFixed(3),
      });

      setWeightInput(grossWeight.toFixed(3));
      setTareWeight(tareWeightValue.toFixed(3));
      setNetWeight(netWeightValue.toFixed(3));

      calculateAmount(netWeightValue, rate);
    }
    setShowModal(false);
  };

  // Handler for Weight change
  const handleWeightChange = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, "");
    if ((value.match(/\./g) || []).length > 1) return;
    setWeightInput(value);
  };

  // Handler for Weight blur
  const handleWeightBlur = () => {
    const value = parseFloat(weightInput);
    if (!isNaN(value)) {
      setWeightInput(value.toFixed(3));
    } else {
      setWeightInput("0.000");
    }
  };

  // Handler for Rate change
  const handleRateChange = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, "");
    setRate(value);

    calculateAmount(netWeight, value);
  };

  // Handler for Bags change
  const handleBagsChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    setBags(value);
    const gAmtValue = value
      ? (parseInt(value) * gAmtMultiplier).toString()
      : gAmtMultiplier.toString();
    setGAmt(gAmtValue);
    calculateNetAmount(amount, gAmtValue);
  };

  // Handler for Bags blur
  const handleBagsBlur = () => {
    if (bags && parseInt(bags, 10) > 0) {
      setSerialCount(parseInt(bags, 10));
      setShowModal(true);
    }
  };

  // Function to calculate Amount
  const calculateAmount = (netWeight, rate) => {
    const rateValue = parseFloat(rate) || 0;
    const netWeightValue = parseFloat(netWeight) || 0;

    const amountValue = ((rateValue * netWeightValue) / 100).toFixed(2);
    setAmount(amountValue);

    const gAmtValue = (parseInt(bags) * gAmtMultiplier).toFixed(2);
    setGAmt(gAmtValue);

    const netAmount = (parseFloat(amountValue) + parseFloat(gAmtValue)).toFixed(
      2
    );
    setNetAmt(netAmount);
  };

  // Function to calculate Net Amount
  const calculateNetAmount = (amountValue, gAmtValue) => {
    const netAmount = (parseFloat(amountValue) + parseFloat(gAmtValue)).toFixed(
      2
    );
    setNetAmt(netAmount);
  };

  // Function to validate the form
  const isFormValid = () => {
    const valid =
      partyName &&
      itemName &&
      mark &&
      bags &&
      rate &&
      partyNameValid &&
      itemNameValid &&
      isSuggestionSelected;
    return valid;
  };

  // Handler to add or update an item
  const handleAddItem = () => {
    const currentDate = new Date().toISOString();

    const newItem = {
      itemName,
      itemId,
      gradeName: selectedGrade || "N/A",
      partyName,
      mark,
      bags,
      weight: weightInput,
      tareWeight,
      netWeight,
      rate,
      amount,
      gAmt,
      netAmt,
      dropdown,
      town,
      description,
      currentDate,
      modalData,
      billNo1,
      billDate,
      sonOf,
    };

    if (editIndex !== null) {
      const updatedItems = [...transactionItems];
      updatedItems[editIndex] = newItem;
      setTransactionItems(updatedItems);
      setEditIndex(null);
    } else {
      setTransactionItems([newItem, ...transactionItems]);
    }

    setModalData({
      weights: [],
      serials: [],
      startFrom: 1,
      tareWeight: "0.000",
      netWeight: "0.000",
    });

    setMark("");
    setBags("");
    setWeightInput("0.000");
    setTareWeight("0.000");
    setNetWeight("0.000");
    setRate("");
    setAmount("0.00");
    setGAmt(gAmtMultiplier.toString());
    setNetAmt("0.00");
    setDropdown(transactionTypes.length > 0 ? transactionTypes[0].ttId : "");
    setDescription("");

    setIsWeightEditable(false);
  };

  // Handler to edit an existing item
  const handleEdit = (index) => {
    const item = transactionItems[index];
    setItemName(item.itemName || "");
    setItemId(item.itemId || "");
    setSelectedGrade(item.gradeName || "");
    setPartyName(item.partyName || "");
    setMark(item.mark || "");
    setBags(item.bags || "");
    setWeightInput(item.weight || "0.000");
    setTareWeight(item.tareWeight || "0.000");
    setNetWeight(item.netWeight || "0.000");
    setRate(item.rate || "");
    setAmount(item.amount || "0.00");
    setGAmt(item.gAmt || gAmtMultiplier.toString());
    setNetAmt(item.netAmt || "0.00");
    setSerialCount(item.bags || 0);
    setDropdown(item.dropdown || transactionTypes[0]?.ttId || "");
    setTown(item.town || "");
    setDescription(item.description || "");

    setBillNo1(item.billNo1 || "");
    setSonOf(item.sonOf || "");

    setEditIndex(index);
    setModalData(item.modalData || { weights: [], serials: [], startFrom: 1 });
    setIsSuggestionSelected(true);
    setIsWeightEditable(item.modalData.weights.length === 0);
  };

  // Handler to delete an item
  const handleDelete = (index) => {
    const updatedItems = transactionItems.filter((_, i) => i !== index);
    setTransactionItems(updatedItems);
  };

  // Filtered items based on search query
  const filteredItems = transactionItems.filter((item) =>
    item.itemName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Function to render table rows
  const renderTableRow = (item, index, prevItemDetails) => {
    const shouldRenderDetails =
      !prevItemDetails.itemName ||
      prevItemDetails.itemName !== item.itemName ||
      prevItemDetails.partyName !== item.partyName ||
      prevItemDetails.mark !== item.mark;

    if (shouldRenderDetails) {
      prevItemDetails.itemName = item.itemName;
      prevItemDetails.partyName = item.partyName;
      prevItemDetails.mark = item.mark;
    }

    return (
      <React.Fragment key={index}>
        {shouldRenderDetails && (
          <tr className="trHeader">
            <td colSpan={4}>
              {item.itemName} - {item.partyName}
            </td>
            <td colSpan={5} className="mark">
              {item.mark}
            </td>
          </tr>
        )}
        <tr>
          <td>{item.bags}</td>
          <td>{item.weight}</td>
          <td>{item.rate}</td>
          <td>{item.gAmt}</td>
          <td>{item.netAmt}</td>
          <td>
            <img
              id="itemImg"
              src={preview}
              alt="preview Download"
              style={{ cursor: "pointer" }}
              onClick={() => handleShowPopup(item)}
            />
          </td>
          <td>
            <img
              src={editIcon}
              alt="Edit"
              onClick={() => handleEdit(index)}
              style={{ cursor: "pointer" }}
            />
          </td>
          <td>
            <img
              src={trashIcon}
              alt="Delete"
              onClick={() => handleDelete(index)}
              style={{ cursor: "pointer" }}
            />
          </td>
        </tr>
      </React.Fragment>
    );
  };

  const prevItemDetails = {};

  // Handler to save and either create a new transaction or update existing
  const handleSaveAndNewOrUpdate = () => {
    if (buttonLabel === "Update") {
      const existingPurchases =
        JSON.parse(localStorage.getItem("Purchase")) || [];
      localStorage.setItem(
        "Purchase",
        JSON.stringify([...existingPurchases, ...transactionItems])
      );

      setPartyName("");
      localStorage.removeItem("partyName");
      setTown("");
      localStorage.removeItem("town");
      setItemName("");
      localStorage.removeItem("itemName");
      setSelectedGrade("");
      setSonOf("");

      setTransactionItems([]);
      localStorage.removeItem("transactionItems");

      setButtonLabel("Save & New");

      generateNextBillNo();
    } else {
      const currentDate = new Date().toISOString();
      const itemsWithDate = transactionItems.map((item) => ({
        ...item,
        currentDate,
      }));

      try {
        const existingPurchases =
          JSON.parse(localStorage.getItem("Purchase")) || [];
        localStorage.setItem(
          "Purchase",
          JSON.stringify([...existingPurchases, ...itemsWithDate])
        );

        setTransactionItems([]);
        localStorage.removeItem("transactionItems");
        localStorage.removeItem("modalData");

        generateNextBillNo();

        setItemName("");
        localStorage.removeItem("itemName");
        setPartyName("");
        localStorage.removeItem("partyName");
        setTown("");
        localStorage.removeItem("town");
        setSelectedGrade("");
        setItemId("");
        setIsSuggestionSelected(false);
        setItemNameValid(true);
        setPartyNameValid(true);
        setSonOf("");

        setMark("");
        setBags("");
        setWeightInput("0.000");
        setTareWeight("0.000");
        setNetWeight("0.000");
        setRate("");
        setAmount("0.00");
        setGAmt(gAmtMultiplier.toString());
        setNetAmt("0.00");
        setDropdown(
          transactionTypes.length > 0 ? transactionTypes[0].ttId : ""
        );
        setDescription("");
        setIsWeightEditable(false);
      } catch (error) {
        console.error("Failed to save transaction data", error);
      }
    }
  };

  // Function to fetch Party Name suggestions
  const fetchPartyNameSuggestions = async (query) => {
    try {
      // const response = await api.get(`/Master/Accounts?AccountName=${query}`);
      const CompID = localStorage.getItem("CompID");
      const response = await api.get(
        `/Master/Accounts?AccountName=${query}&CompID=${CompID}`
      );
      const mappedSuggestions = response.data.map((suggestion) => ({
        AccountName: suggestion.act_Name,
        Town: suggestion.act_Town,
        sonOf: suggestion.act_SonOf,
      }));
      setSuggestions(mappedSuggestions);
      localStorage.setItem(
        "partyNameSuggestions",
        JSON.stringify(mappedSuggestions)
      );
    } catch (error) {
      console.error("Error fetching party name suggestions", error);
      const cachedPartyNames =
        JSON.parse(localStorage.getItem("partyNameSuggestions")) || [];
      setSuggestions(cachedPartyNames);
    }
  };

  // Function to fetch Item Name suggestions
  const fetchItemNameSuggestions = async (query) => {
    if (query.length >= 3) {
      try {
        // const response = await api.get(`/Master/ListofItems?itemName=${query}`);
        const CompID = localStorage.getItem("CompID");
        const response = await api.get(
          `/Master/ListofItems?itemName=${query}&CompID=${CompID}`
        );
        const mappedSuggestions = response.data.map((suggestion) => ({
          itemName: suggestion.itemName,
          gAmt: suggestion.gunnyAmt.toString(),
          itemId: suggestion.itemid || suggestion.itemId || suggestion.id,
        }));
        setItemSuggestions(mappedSuggestions);
        localStorage.setItem(
          "itemNameSuggestions",
          JSON.stringify(mappedSuggestions)
        );

        if (mappedSuggestions.length > 0) {
          setGAmt(mappedSuggestions[0].gAmt);
        }

        const gAmtMultiplierValue =
          response.data.length > 0 ? response.data[0].gunnyAmt : 0;
        setGAmtMultiplier(gAmtMultiplierValue);
      } catch (error) {
        console.error("Error fetching item name suggestions", error);
        const cachedItemNames =
          JSON.parse(localStorage.getItem("itemNameSuggestions")) || [];
        setItemSuggestions(cachedItemNames);
        setGAmt("0");
        setGAmtMultiplier(0);
      }
    } else {
      setItemSuggestions([]);
    }
  };

  // Handler for Item Name change
  const handleItemNameChange = (e) => {
    const value = e.target.value;
    setItemName(value);
    localStorage.setItem("itemName", value);
    setIsSuggestionSelected(false);
    setItemNameValid(true);
    fetchItemNameSuggestions(value);
    setSelectedGrade("");
    setGradeOptions([]);
    setItemId("");
  };

  // Handler for Item Name blur
  const handleItemNameBlur = () => {
    if (!isSuggestionSelected) {
      if (
        !itemSuggestions.find((suggestion) => suggestion.itemName === itemName)
      ) {
        setItemNameValid(false);
      }
    }
    setItemNameFocused(false);
  };

  // Handler for Item Name focus
  const handleItemNameFocus = () => {
    setItemNameFocused(true);
  };

  // useEffect to fetch Grade Options based on selected itemId
  useEffect(() => {
    if (itemId) {
      fetchGradeOptions(itemId);
    } else {
      setGradeOptions([]);
      setSelectedGrade("");
    }
  }, [itemId]);

  // Function to fetch Grade Options
  const fetchGradeOptions = async (itemId) => {
    try {
      const CompID = localStorage.getItem("CompID");
      const response = await api.get(
        `/Master/GetCommodities?CompID=${CompID}&itemid=${itemId} `
      );
      if (Array.isArray(response.data)) {
        const grades = response.data.map((grade) => ({
          gradeId: grade.cmdTypeID,
          gradeName: grade.cmdDesc,
        }));
        setGradeOptions(grades);
      } else {
        console.error("Unexpected API response structure:", response.data);
        setGradeOptions([]);
      }
    } catch (error) {
      console.error("Error fetching grade options:", error);
      setGradeOptions([]);
    }
  };

  // Handlers to open and close Charge Modal
  const openChargeModal = () => setChargeModalOpen(true);
  const closeChargeModal = () => setChargeModalOpen(false);

  // Handler to save charges
  const handleSaveCharges = (updatedCharges) => {
    setCharges2(updatedCharges);
    localStorage.setItem("charges2", JSON.stringify(updatedCharges));
  };

  // Handler to close alert
  const closeAlert = () => setShowAlert(false);

  return (
    <>
      <header className="main-header">
        <NavLink to="/">
          <img src={logo} alt="Kliq Soft" className="logo" />
        </NavLink>
      </header>
      <main>
        <div className="transactions">
          <p>Transactions</p>
          <p>
            <img src={purchase} alt="rupee img" />
            Purchase
          </p>
        </div>

        <div className="goback">
          <NavLink to="/">
            <svg
              fill="#185492"
              className="left-arrow"
              xmlns="http://www.w3.org/2000/svg"
              width={22}
              height={22}
              viewBox="0 0 20 20"
            >
              <path d="M10,0A10,10,0,1,0,20,10,10,10,0,0,0,10,0Zm6,11H7.38l2.376,2.375L8.342,14.789,3.607,10.055,8.342,5.322,9.756,6.736,7.49,9H16Z" />
            </svg>
          </NavLink>
        </div>

        {showAlert && (
          <>
            <div className="alert-popup-overlay" onClick={closeAlert}></div>
            <div className="alert-popup">
              <p>Party Name already exists on this day.</p>
              <button onClick={handlePopupConfirm}>OK</button>
            </div>
          </>
        )}

        <div className="form-container clear">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <form className="form form1">
              <div className="input-box active-grey focus">
                <label className="input-label">Transaction Type</label>
                <select
                  className="input-1"
                  value={dropdown}
                  ref={dropdownRef}
                  onChange={(e) => setDropdown(e.target.value)}
                >
                  {transactionTypes.map((type) => (
                    <option key={type.ttId} value={type.ttId}>
                      {type.ttName}
                    </option>
                  ))}
                </select>
              </div>

              <div className="row row-bill-info">
                <div className="input-box col focus">
                  <label className="input-label">Bill No.</label>
                  <input
                    type="text"
                    className="input-1"
                    value={billNo1}
                    readOnly
                  />
                </div>
                <div className="input-box col focus">
                  <label className="input-label">Bill Date</label>
                  <input
                    type="text"
                    className="input-1"
                    value={billDate}
                    readOnly
                  />
                </div>
              </div>

              <div className="row">
                <div className="input-box col focus">
                  <label
                    className={`input-label ${
                      !partyNameValid ? "label-invalid" : ""
                    }`}
                  >
                    Party Name *
                  </label>
                  <input
                    type="text"
                    id="inputField"
                    className={`input-1 ${
                      !partyNameValid ? "input-invalid" : ""
                    }`}
                    ref={partyNameRef}
                    required
                    value={partyName || ""}
                    onChange={handlePartyNameChange}
                    onBlur={handlePartyNameBlur}
                  />
                  {suggestions.length > 0 && (
                    <ul className="suggestions-list">
                      {suggestions.map((suggestion, index) => (
                        <li
                          key={index}
                          onMouseDown={() =>
                            handleSuggestionClick(suggestion, "party")
                          }
                        >
                          {suggestion.AccountName}
                        </li>
                      ))}
                    </ul>
                  )}
                  {!partyNameValid && (
                    <div className="invalid-feedback">
                      Party name not exists.{" "}
                      <NavLink to="/accountpage" className="add-new">
                        Add New Account
                      </NavLink>
                    </div>
                  )}
                </div>
                <div className="input-box col focus">
                  <label className="input-label">Town</label>
                  <input
                    type="text"
                    className="input-1"
                    ref={townRef}
                    value={town || ""}
                    onChange={(e) => setTown(e.target.value)}
                  />
                </div>
                <button
                  type="button"
                  className="toggle-btn"
                  onClick={() => setIsDescriptionVisible(!isDescriptionVisible)}
                >
                  {isDescriptionVisible ? "-" : "+"}
                </button>
              </div>

              {isDescriptionVisible && (
                <div className="input-box focus">
                  <label className="input-label">Description</label>
                  <textarea
                    className="input-1"
                    ref={descriptionRef}
                    value={description || ""}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              )}
            </form>
          )}

          <form className="form form2">
            <div className="row row1">
              <div className="input-box col col1 focus">
                <label className="input-label">Item Name *</label>
                <input
                  type="text"
                  className={`input-1 ${!itemNameValid ? "input-invalid" : ""}`}
                  ref={itemNameRef}
                  required
                  value={itemName || ""}
                  onChange={handleItemNameChange}
                  onBlur={handleItemNameBlur}
                  onFocus={handleItemNameFocus}
                />
                {itemNameFocused && itemSuggestions.length > 0 && (
                  <ul className="suggestions-list">
                    {itemSuggestions.map((suggestion, index) => (
                      <li
                        key={index}
                        onMouseDown={() =>
                          handleSuggestionClick(suggestion, "item")
                        }
                      >
                        {suggestion.itemName}
                      </li>
                    ))}
                  </ul>
                )}
                {!itemNameValid && (
                  <div className="invalid-feedback">Item name not exists.</div>
                )}
              </div>

              <div className="input-box col col2 focus grade-name">
                <label className="input-label">Grade Name</label>{" "}
                <select
                  className={`input-1`}
                  value={selectedGrade}
                  onChange={(e) => setSelectedGrade(e.target.value)}
                >
                  <option value="">Select Grade</option>
                  {gradeOptions.map((grade) => (
                    <option key={grade.gradeId} value={grade.gradeName}>
                      {grade.gradeName}
                    </option>
                  ))}
                </select>
              </div>

              <div className="input-box col col3 focus">
                <label className="input-label">Mark *</label>
                <input
                  type="text"
                  className="input-1"
                  ref={markRef}
                  value={mark || ""}
                  onChange={(e) => setMark(e.target.value)}
                />
              </div>
            </div>
            <div className="row row2">
              <div className="input-box col col1 focus">
                <label className="input-label">Bags *</label>
                <input
                  type="text"
                  className="input-1"
                  ref={bagsRef}
                  inputMode="numeric"
                  value={bags || ""}
                  onChange={handleBagsChange}
                  onBlur={handleBagsBlur}
                />
              </div>
              <div className="input-box col col3 focus">
                <label className="input-label">Weight</label>
                <input
                  type="text"
                  className="input-1 numeric-input three-decimal"
                  inputMode="numeric"
                  ref={weightRef}
                  value={weightInput}
                  readOnly={!isWeightEditable}
                  onChange={handleWeightChange}
                  onBlur={handleWeightBlur}
                />
              </div>
            </div>
            <div className="row row3">
              <div className="input-box col col1 focus">
                <label className="input-label">Rate *</label>
                <input
                  type="text"
                  className="input-1 numeric-input"
                  inputMode="numeric"
                  ref={rateRef}
                  value={rate || ""}
                  onChange={handleRateChange}
                />
              </div>
              <div className="input-box col col2 focus">
                <label className="input-label">Amount</label>
                <input
                  type="text"
                  className="input-1 numeric-input"
                  value={amount}
                  readOnly
                />
              </div>
            </div>
            <div className="row row4">
              <div className="input-box col col1 focus">
                <label className="input-label">G.Amt</label>
                <input
                  type="text"
                  className="input-1 numeric-input"
                  value={gAmt}
                  readOnly
                />
              </div>
              <div className="input-box col col2 focus">
                <label className="input-label">Net Amt</label>
                <input
                  type="text"
                  className="input-1 numeric-input"
                  value={netAmt}
                  readOnly
                />
              </div>
            </div>
            <button
              type="button"
              id="submitButton"
              className={`btn btn-primary pull-right ${
                !isFormValid() ? "btn-disabled" : ""
              }`}
              disabled={!isFormValid()}
              onClick={handleAddItem}
            >
              {editIndex !== null ? "Update Item" : "Add Item"}
            </button>
            <div className="listAndSave">
              <button
                type="button"
                className="btn btn-save"
                onClick={handleSaveAndNewOrUpdate}
              >
                {buttonLabel}
              </button>
            </div>
          </form>
        </div>

        <div
          className="item-search"
          style={{ marginBottom: filteredItems.length > 0 ? "0" : "3rem" }}
        >
          <input
            type="text"
            name="search"
            id="search"
            placeholder="Item Name"
            value={searchQuery || ""}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <button className="btn btn-search">View</button>
        </div>

        {filteredItems.length > 0 && (
          <div
            className="item-list"
            style={{ overflowX: "auto", marginBottom: "3.5rem" }}
          >
            <table>
              <thead>
                <tr>
                  <th scope="col">Bags</th>
                  <th scope="col">Weight</th>
                  <th scope="col">Rate</th>
                  <th scope="col">G.Amt</th>
                  <th scope="col">Net Amt</th>
                  <th
                    scope="col"
                    className="action"
                    colSpan={3}
                    style={{ opacity: 0 }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredItems.map((item, index) =>
                  renderTableRow(item, index, prevItemDetails)
                )}
              </tbody>
              <tfoot>
                <tr>
                  <th colSpan={8}>No. of Items: {transactionItems.length}</th>
                </tr>
              </tfoot>
            </table>
          </div>
        )}
      </main>
      {showModal && (
        <ModalWindow
          serialCount={serialCount}
          onClose={handleModalClose}
          initialWeights={modalData.weights}
          initialSerials={modalData.serials}
          startSerial={modalData.startFrom}
        />
      )}

      {chargeModalOpen && (
        <ChargeModal
          isOpen={chargeModalOpen}
          onClose={closeChargeModal}
          chargesData={charges2}
          onSave={handleSaveCharges}
        />
      )}

      {showPopup && (
        <WeightListPopup
          onClose={handleClosePopup}
          itemData={selectedItemData}
        />
      )}
    </>
  );
}

export default Transactions;
