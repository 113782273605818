import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../images/Logo.jpg";
import dateRange from "../images/DateRange.png";
import edit from "../images/edit.svg";
import trash from "../images/trash.svg";
import jsonDownloader from "../images/jsonDownloader.svg";
import { utils, writeFile } from "xlsx";
import "./styles/AccountAndTransactions.css";
import "./styles/ItemsList.css";

const ListPage = () => {
  const [purchaseList, setPurchaseList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(getTodayDate());
  const navigate = useNavigate();

  // Get today's date in YYYY-MM-DD format
  function getTodayDate() {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  }

  useEffect(() => {
    const purchaseData = localStorage.getItem("Purchase");
    if (purchaseData) setPurchaseList(JSON.parse(purchaseData));
  }, []);

  const handleDelete = (index) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this item?"
    );
    if (confirmDelete) {
      const filteredList = purchaseList.filter(
        (item) => convertDateToISO(item.billDate) === selectedDate
      );
      const itemToDelete = filteredList[index];
      const updatedPurchaseList = purchaseList.filter(
        (item) => item !== itemToDelete
      );
      setPurchaseList(updatedPurchaseList);
      localStorage.setItem("Purchase", JSON.stringify(updatedPurchaseList));
    }
  };

  const handleEdit = (index) => {
    const filteredList = purchaseList.filter(
      (item) => convertDateToISO(item.billDate) === selectedDate
    );
    const itemToEdit = filteredList[index];
    const transactionItems =
      JSON.parse(localStorage.getItem("transactionItems")) || [];
    transactionItems.push(itemToEdit);
    localStorage.setItem("transactionItems", JSON.stringify(transactionItems));

    const updatedPurchaseList = purchaseList.filter(
      (item) => item !== itemToEdit
    );
    setPurchaseList(updatedPurchaseList);
    localStorage.setItem("Purchase", JSON.stringify(updatedPurchaseList));

    navigate("/transactions", { state: { editMode: true } });
  };

  const handleJsonDownload = (item) => {
    const json = JSON.stringify(item, null, 2);
    const blob = new Blob([json], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${item.billNo || "data"}.json`;
    a.click();
    URL.revokeObjectURL(url);
  };

  const handleExcelDownload = () => {
    const dataToDownload = purchaseList
      .filter((item) => convertDateToISO(item.billDate) === selectedDate) // Filter by selected date
      .map((item) => ({
        ...item,
        weights:
          item.modalData && item.modalData.weights
            ? item.modalData.weights.join(", ")
            : "",
        serialNumbers:
          item.modalData && item.modalData.serials
            ? item.modalData.serials.join(", ") // Use 'serials' for Purchase
            : "",
        startFrom:
          item.modalData && item.modalData.startFrom
            ? item.modalData.startFrom
            : "",
      }));

    // Create a worksheet and workbook, then download as Excel
    const worksheet = utils.json_to_sheet(dataToDownload);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Purchase");
    writeFile(workbook, `Purchase-${selectedDate}.xlsx`);
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const convertDateToISO = (date) => {
    const [dd, mm, yyyy] = date.split("-");
    const day = dd.padStart(2, "0");
    const month = mm.padStart(2, "0");
    return `${yyyy}-${month}-${day}`;
  };

  const filteredPurchaseList = purchaseList.filter(
    (item) => convertDateToISO(item.billDate) === selectedDate
  );

  return (
    <div>
      <header className="main-header">
        <NavLink to="/">
          <img src={logo} alt="Kliq Soft" className="logo" />
        </NavLink>
      </header>

      <main>
        <div className="transactions">
          <p>Purchase List</p>
        </div>

        <div className="goback">
          <NavLink to="/transactions">
            <svg
              fill="#185492"
              className="left-arrow"
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 20 20"
            >
              <path d="M10,0A10,10,0,1,0,20,10,10,10,0,0,0,10,0Zm6,11H7.38l2.376,2.375L8.342,14.789,3.607,10.055,8.342,5.322,9.756,6.736,7.49,9H16Z" />
            </svg>
          </NavLink>
        </div>

        <div className="dropdown-container">
          {/* Add Purchase Button */}
          <div className="dropdownsidebutton">
            <NavLink to="/transactions">
              <button>Add Purchase</button>
            </NavLink>
          </div>

          {/* Download Button */}
          <div className="download-button">
            <button onClick={handleExcelDownload}>
              Download Purchase.xlsx
            </button>
          </div>
        </div>

        <div className="datePicker">
          <label htmlFor="datePicker">
            <img src={dateRange} alt="Select Date" />
          </label>
          <input
            type="date"
            id="datePicker"
            value={selectedDate}
            onChange={handleDateChange}
          />
        </div>

        {/* Table rendering */}
        <div
          className="item-list"
          style={{ overflowX: "auto", marginBottom: "3.5rem" }}
        >
          <table>
            <thead>
              <tr>
                <th scope="col" className="itemAndBill">
                  Dt. &amp; B.No
                </th>
                <th scope="col">Bags</th>
                <th scope="col">Weight</th>
                <th scope="col">Net Amt</th>
                <th
                  scope="col"
                  className="action"
                  colSpan="3"
                  style={{ opacity: 0 }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredPurchaseList.map((item, index) => (
                <React.Fragment key={item.billNo || index}>
                  <tr>
                    <td colSpan="4">
                      {item.partyName} - {item.town}
                    </td>
                    <td colSpan="3" className="mark">
                      {item.mark}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {item.billDate} &amp; {item.billNo1}
                    </td>
                    <td>{item.bags}</td>
                    <td>{item.weight}</td>
                    <td>{item.netAmt}</td>
                    <td>
                      <img
                        src={jsonDownloader}
                        alt="Json Download"
                        onClick={() => handleJsonDownload(item)}
                        style={{ cursor: "pointer" }}
                      />
                    </td>
                    <td>
                      <img
                        src={edit}
                        alt="Edit"
                        onClick={() => handleEdit(index)}
                        style={{ cursor: "pointer" }}
                      />
                    </td>
                    <td>
                      <img
                        src={trash}
                        alt="Delete"
                        onClick={() => handleDelete(index)}
                        style={{ cursor: "pointer" }}
                      />
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </main>
    </div>
  );
};

export default ListPage;

// import React, { useState, useEffect } from "react";
// import { NavLink, useNavigate } from "react-router-dom";
// import logo from "../images/Logo.jpg";
// import dateRange from "../images/DateRange.png";
// import edit from "../images/edit.svg";
// import trash from "../images/trash.svg";
// import jsonDownloader from "../images/jsonDownloader.svg";
// import { utils, writeFile } from "xlsx";
// import "./styles/AccountAndTransactions.css";
// import "./styles/ItemsList.css";

// const ListPage = () => {
//   const [purchaseList, setPurchaseList] = useState([]);
//   const [selectedDate, setSelectedDate] = useState(getTodayDate());
//   const navigate = useNavigate();

//   // Get today's date in YYYY-MM-DD format
//   function getTodayDate() {
//     const today = new Date();
//     const yyyy = today.getFullYear();
//     const mm = String(today.getMonth() + 1).padStart(2, "0");
//     const dd = String(today.getDate()).padStart(2, "0");
//     return `${yyyy}-${mm}-${dd}`;
//   }

//   useEffect(() => {
//     const purchaseData = localStorage.getItem("Purchase");
//     if (purchaseData) setPurchaseList(JSON.parse(purchaseData));
//   }, []);

//   const handleDelete = (index) => {
//     const confirmDelete = window.confirm(
//       "Are you sure you want to delete this item?"
//     );
//     if (confirmDelete) {
//       const filteredList = purchaseList.filter(
//         (item) => convertDateToISO(item.billDate) === selectedDate
//       );
//       const itemToDelete = filteredList[index];
//       const updatedPurchaseList = purchaseList.filter(
//         (item) => item !== itemToDelete
//       );
//       setPurchaseList(updatedPurchaseList);
//       localStorage.setItem("Purchase", JSON.stringify(updatedPurchaseList));
//     }
//   };

//   const handleEdit = (index) => {
//     const itemToEdit = purchaseList[index];
//     const transactionItems =
//       JSON.parse(localStorage.getItem("transactionItems")) || [];
//     transactionItems.push(itemToEdit);
//     localStorage.setItem("transactionItems", JSON.stringify(transactionItems));

//     const updatedPurchaseList = purchaseList.filter((_, i) => i !== index);
//     setPurchaseList(updatedPurchaseList);
//     localStorage.setItem("Purchase", JSON.stringify(updatedPurchaseList));

//     navigate("/transactions", { state: { editMode: true } });
//   };

//   const handleJsonDownload = (item) => {
//     const json = JSON.stringify(item, null, 2);
//     const blob = new Blob([json], { type: "application/json" });
//     const url = URL.createObjectURL(blob);
//     const a = document.createElement("a");
//     a.href = url;
//     a.download = `${item.billNo || "data"}.json`;
//     a.click();
//     URL.revokeObjectURL(url);
//   };

//   const handleExcelDownload = () => {
//     const dataToDownload = purchaseList
//       .filter((item) => convertDateToISO(item.billDate) === selectedDate) // Filter by selected date
//       .map((item) => ({
//         ...item,
//         weights:
//           item.modalData && item.modalData.weights
//             ? item.modalData.weights.join(", ")
//             : "",
//         serialNumbers:
//           item.modalData && item.modalData.serials
//             ? item.modalData.serials.join(", ") // Use 'serials' for Purchase
//             : "",
//         startFrom:
//           item.modalData && item.modalData.startFrom
//             ? item.modalData.startFrom
//             : "",
//       }));

//     // Create a worksheet and workbook, then download as Excel
//     const worksheet = utils.json_to_sheet(dataToDownload);
//     const workbook = utils.book_new();
//     utils.book_append_sheet(workbook, worksheet, "Purchase");
//     writeFile(workbook, `Purchase-${selectedDate}.xlsx`);
//   };

//   const handleDateChange = (e) => {
//     setSelectedDate(e.target.value);
//   };

//   const convertDateToISO = (date) => {
//     const [dd, mm, yyyy] = date.split("-");
//     const day = dd.padStart(2, "0");
//     const month = mm.padStart(2, "0");
//     return `${yyyy}-${month}-${day}`;
//   };

//   const filteredPurchaseList = purchaseList.filter(
//     (item) => convertDateToISO(item.billDate) === selectedDate
//   );

//   return (
//     <div>
//       <header className="main-header">
//         <NavLink to="/">
//           <img src={logo} alt="Kliq Soft" className="logo" />
//         </NavLink>
//       </header>

//       <main>
//         <div className="transactions">
//           <p>Purchase List</p>
//         </div>

//         <div className="goback">
//           <NavLink to="/transactions">
//             <svg
//               fill="#185492"
//               className="left-arrow"
//               xmlns="http://www.w3.org/2000/svg"
//               width="22"
//               height="22"
//               viewBox="0 0 20 20"
//             >
//               <path d="M10,0A10,10,0,1,0,20,10,10,10,0,0,0,10,0Zm6,11H7.38l2.376,2.375L8.342,14.789,3.607,10.055,8.342,5.322,9.756,6.736,7.49,9H16Z" />
//             </svg>
//           </NavLink>
//         </div>

//         <div className="dropdown-container">
//           <div className="download-button">
//             <button onClick={handleExcelDownload}>
//               Download Purchase.xlsx
//             </button>
//           </div>
//         </div>

//         <div className="datePicker">
//           <label htmlFor="datePicker">
//             <img src={dateRange} alt="Select Date" />
//           </label>
//           <input
//             type="date"
//             id="datePicker"
//             value={selectedDate}
//             onChange={handleDateChange}
//           />
//         </div>

//         {/* Table rendering */}
//         <div
//           className="item-list"
//           style={{ overflowX: "auto", marginBottom: "3.5rem" }}
//         >
//           <table>
//             <thead>
//               <tr>
//                 <th scope="col" className="itemAndBill">
//                   Dt. &amp; B.No
//                 </th>
//                 <th scope="col">Bags</th>
//                 <th scope="col">Weight</th>
//                 <th scope="col">Net Amt</th>
//                 <th
//                   scope="col"
//                   className="action"
//                   colSpan="3"
//                   style={{ opacity: 0 }}
//                 >
//                   Action
//                 </th>
//               </tr>
//             </thead>
//             <tbody>
//               {filteredPurchaseList.map((item, index) => (
//                 <React.Fragment key={index}>
//                   <tr>
//                     <td colSpan="4">
//                       {item.partyName} - {item.town}
//                     </td>
//                     <td colSpan="3" className="mark">
//                       {item.mark}
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>
//                       {item.billDate} &amp; {item.billNo1}
//                     </td>
//                     <td>{item.bags}</td>
//                     <td>{item.weight}</td>
//                     <td>{item.netAmt}</td>
//                     <td>
//                       <img
//                         src={jsonDownloader}
//                         alt="Json Download"
//                         onClick={() => handleJsonDownload(item)}
//                         style={{ cursor: "pointer" }}
//                       />
//                     </td>
//                     <td>
//                       <img
//                         src={edit}
//                         alt="Edit"
//                         onClick={() => handleEdit(index)}
//                         style={{ cursor: "pointer" }}
//                       />
//                     </td>
//                     <td>
//                       <img
//                         src={trash}
//                         alt="Delete"
//                         onClick={() => handleDelete(index)}
//                         style={{ cursor: "pointer" }}
//                       />
//                     </td>
//                   </tr>
//                 </React.Fragment>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       </main>
//     </div>
//   );
// };

// export default ListPage;

// import React, { useState, useEffect } from "react";
// import { NavLink, useNavigate } from "react-router-dom";
// import logo from "../images/Logo.jpg";
// import dateRange from "../images/DateRange.png";
// import edit from "../images/edit.svg";
// import trash from "../images/trash.svg";
// import jsonDownloader from "../images/jsonDownloader.svg";
// import { utils, writeFile } from "xlsx"; // Import XLSX utilities
// import "./styles/AccountAndTransactions.css";
// import "./styles/ItemsList.css";

// const ListPage = () => {
//   const [selectedOption, setSelectedOption] = useState("AcSale");
//   const [purchaseList, setPurchaseList] = useState([]);
//   const [platformSaleList, setPlatformSaleList] = useState([]);
//   const [AcSaleList, setAcSaleList] = useState([]);
//   const [selectedDate, setSelectedDate] = useState(getTodayDate());
//   const navigate = useNavigate();

//   // Get today's date in YYYY-MM-DD format
//   function getTodayDate() {
//     const today = new Date();
//     const yyyy = today.getFullYear();
//     const mm = String(today.getMonth() + 1).padStart(2, "0");
//     const dd = String(today.getDate()).padStart(2, "0");
//     return `${yyyy}-${mm}-${dd}`;
//   }

//   useEffect(() => {
//     const purchaseData = localStorage.getItem("Purchase");
//     const platformSaleData = localStorage.getItem("PlatformSale");
//     const AcSaleData = localStorage.getItem("AcSale");

//     if (purchaseData) setPurchaseList(JSON.parse(purchaseData));
//     if (platformSaleData) setPlatformSaleList(JSON.parse(platformSaleData));
//     if (AcSaleData) setAcSaleList(JSON.parse(AcSaleData));
//   }, []);

//   const handleDelete = (index) => {
//     const confirmDelete = window.confirm(
//       "Are you sure you want to delete this item?"
//     );
//     if (confirmDelete) {
//       if (selectedOption === "Purchase") {
//         const filteredList = purchaseList.filter(
//           (item) => convertDateToISO(item.billDate) === selectedDate
//         );
//         const itemToDelete = filteredList[index];
//         const updatedPurchaseList = purchaseList.filter(
//           (item) => item !== itemToDelete
//         );
//         setPurchaseList(updatedPurchaseList);
//         localStorage.setItem("Purchase", JSON.stringify(updatedPurchaseList));
//       } else if (selectedOption === "PlatformSale") {
//         const filteredList = platformSaleList.filter(
//           (item) => convertDateToISO(item.billDate) === selectedDate
//         );
//         const itemToDelete = filteredList[index];
//         const updatedPlatformSaleList = platformSaleList.filter(
//           (item) => item !== itemToDelete
//         );
//         setPlatformSaleList(updatedPlatformSaleList);
//         localStorage.setItem(
//           "PlatformSale",
//           JSON.stringify(updatedPlatformSaleList)
//         );
//       } else if (selectedOption === "AcSale") {
//         const filteredList = AcSaleList.filter(
//           (item) => convertDateToISO(item.billDate) === selectedDate
//         );
//         const itemToDelete = filteredList[index];
//         const updatedAcSaleList = AcSaleList.filter(
//           (item) => item !== itemToDelete
//         );
//         setAcSaleList(updatedAcSaleList);
//         localStorage.setItem("AcSale", JSON.stringify(updatedAcSaleList));
//       }
//     }
//   };

//   const handleEdit = (index) => {
//     if (selectedOption === "Purchase") {
//       const itemToEdit = purchaseList[index];
//       const transactionItems =
//         JSON.parse(localStorage.getItem("transactionItems")) || [];
//       transactionItems.push(itemToEdit);
//       localStorage.setItem(
//         "transactionItems",
//         JSON.stringify(transactionItems)
//       );

//       const updatedPurchaseList = purchaseList.filter((_, i) => i !== index);
//       setPurchaseList(updatedPurchaseList);
//       localStorage.setItem("Purchase", JSON.stringify(updatedPurchaseList));

//       navigate("/transactions", { state: { editMode: true } });
//     } else if (selectedOption === "PlatformSale") {
//       const itemToEdit = platformSaleList[index];
//       const transactionItems2 =
//         JSON.parse(localStorage.getItem("transactionItems2")) || [];
//       transactionItems2.push(itemToEdit);
//       localStorage.setItem(
//         "transactionItems2",
//         JSON.stringify(transactionItems2)
//       );

//       const updatedPlatformSaleList = platformSaleList.filter(
//         (_, i) => i !== index
//       );
//       setPlatformSaleList(updatedPlatformSaleList);
//       localStorage.setItem(
//         "PlatformSale",
//         JSON.stringify(updatedPlatformSaleList)
//       );

//       navigate("/platformsale", { state: { editMode: true } });
//     } else if (selectedOption === "AcSale") {
//       const itemToEdit = AcSaleList[index];
//       const transactionItems3 =
//         JSON.parse(localStorage.getItem("transactionItems3")) || [];
//       transactionItems3.push(itemToEdit);
//       localStorage.setItem(
//         "transactionItems3",
//         JSON.stringify(transactionItems3)
//       );

//       const updatedAcSaleList = AcSaleList.filter((_, i) => i !== index);
//       setAcSaleList(updatedAcSaleList);
//       localStorage.setItem("AcSale", JSON.stringify(updatedAcSaleList));

//       navigate("/Acsale", { state: { editMode: true } });
//     }
//   };

//   const handleJsonDownload = (item) => {
//     const json = JSON.stringify(item, null, 2);
//     const blob = new Blob([json], { type: "application/json" });
//     const url = URL.createObjectURL(blob);
//     const a = document.createElement("a");
//     a.href = url;
//     a.download = `${item.billNo || item.billNo2 || "data"}.json`;
//     a.click();
//     URL.revokeObjectURL(url);
//   };

//   const handleExcelDownload = () => {
//     let dataToDownload;

//     if (selectedOption === "Purchase") {
//       dataToDownload = purchaseList
//         .filter((item) => convertDateToISO(item.billDate) === selectedDate) // Filter by selected date
//         .map((item) => ({
//           ...item,
//           weights:
//             item.modalData && item.modalData.weights
//               ? item.modalData.weights.join(", ")
//               : "",
//           serialNumbers:
//             item.modalData && item.modalData.serials
//               ? item.modalData.serials.join(", ") // Use 'serials' for Purchase
//               : "",
//           startFrom:
//             item.modalData && item.modalData.startFrom
//               ? item.modalData.startFrom
//               : "",
//         }));
//     } else if (selectedOption === "PlatformSale") {
//       dataToDownload = platformSaleList
//         .filter((item) => convertDateToISO(item.billDate) === selectedDate) // Filter by selected date
//         .map((item) => ({
//           ...item,
//           weights:
//             item.modalData && item.modalData.weights
//               ? item.modalData.weights.join(", ")
//               : "",
//           serialNumbers:
//             item.modalData && item.modalData.serials
//               ? item.modalData.serials.join(", ") // Use 'serials' for PlatformSale
//               : "",
//           startFrom:
//             item.modalData && item.modalData.startFrom
//               ? item.modalData.startFrom
//               : "",
//         }));
//     } else if (selectedOption === "AcSale") {
//       dataToDownload = AcSaleList.filter(
//         (item) => convertDateToISO(item.billDate) === selectedDate
//       ) // Filter by selected date
//         .map((item) => ({
//           ...item,
//           weights:
//             item.modalData && item.modalData.weights
//               ? item.modalData.weights.join(", ")
//               : "",
//           serialNumbers:
//             item.modalData && item.modalData.serialNumbers
//               ? item.modalData.serialNumbers.join(", ") // Use 'serialNumbers' for AcSale
//               : "",
//           startFrom:
//             item.modalData && item.modalData.startFrom
//               ? item.modalData.startFrom
//               : "",
//         }));
//     }

//     console.log("Data to Download:", dataToDownload); // Debugging statement to verify the data

//     // Create a worksheet and workbook, then download as Excel
//     const worksheet = utils.json_to_sheet(dataToDownload);
//     const workbook = utils.book_new();
//     utils.book_append_sheet(workbook, worksheet, selectedOption);
//     writeFile(workbook, `${selectedOption}-${selectedDate}.xlsx`); // Include date in filename
//   };

//   const handleDateChange = (e) => {
//     setSelectedDate(e.target.value);
//   };

//   const convertDateToISO = (date) => {
//     const [dd, mm, yyyy] = date.split("-");
//     const day = dd.padStart(2, "0");
//     const month = mm.padStart(2, "0");
//     return `${yyyy}-${month}-${day}`;
//   };

//   const filteredPurchaseList = purchaseList.filter(
//     (item) => convertDateToISO(item.billDate) === selectedDate
//   );

//   const filteredPlatformSaleList = platformSaleList.filter(
//     (item) => convertDateToISO(item.billDate) === selectedDate
//   );

//   const filteredAcSaleList = AcSaleList.filter(
//     (item) => convertDateToISO(item.billDate) === selectedDate
//   );

//   return (
//     <div>
//       <header className="main-header">
//         <NavLink to="/">
//           <img src={logo} alt="Kliq Soft" className="logo" />
//         </NavLink>
//       </header>

//       <main>
//         <div className="transactions">
//           <p>
//             {selectedOption === "Purchase"
//               ? "Purchase List"
//               : selectedOption === "PlatformSale"
//               ? "Platform Sale List"
//               : "AC Sale List"}
//           </p>
//         </div>

//         <div className="goback">
//           <NavLink
//             to={
//               selectedOption === "Purchase"
//                 ? "/transactions"
//                 : selectedOption === "PlatformSale"
//                 ? "/platformsale"
//                 : "/acsale"
//             }
//           >
//             <svg
//               fill="#185492"
//               className="left-arrow"
//               xmlns="http://www.w3.org/2000/svg"
//               width="22"
//               height="22"
//               viewBox="0 0 20 20"
//             >
//               <path d="M10,0A10,10,0,1,0,20,10,10,10,0,0,0,10,0Zm6,11H7.38l2.376,2.375L8.342,14.789,3.607,10.055,8.342,5.322,9.756,6.736,7.49,9H16Z" />
//             </svg>
//           </NavLink>
//         </div>

//         <div className="dropdown-container">
//           <div className="dropdown">
//             <label htmlFor="transaction-type"> </label>
//             <select
//               id="transaction-type"
//               value={selectedOption}
//               onChange={(e) => setSelectedOption(e.target.value)}
//             >
//               {/* <option value="AcSale">AC Sale</option>
//               <option value="PlatformSale">Platform Sale</option> */}
//               <option value="Purchase">Purchase</option>
//             </select>
//           </div>
//           <div className="dropdownsidebutton">
//             <NavLink
//               to={
//                 selectedOption === "Purchase"
//                   ? "/transactions"
//                   : selectedOption === "PlatformSale"
//                   ? "/platformsale"
//                   : "/acsale"
//               }
//             >
//               <button>Add {selectedOption}</button>
//             </NavLink>
//           </div>
//           {/* Download Button */}
//           <div className="download-button">
//             <button onClick={handleExcelDownload}>
//               Download {selectedOption}.xlsx
//             </button>
//           </div>
//         </div>

//         <div className="datePicker">
//           <label htmlFor="datePicker">
//             <img src={dateRange} alt="Select Date" />
//           </label>
//           <input
//             type="date"
//             id="datePicker"
//             value={selectedDate}
//             onChange={handleDateChange}
//           />
//         </div>

//         {/* Table rendering */}
//         {selectedOption === "Purchase" && (
//           <div
//             className="item-list"
//             style={{ overflowX: "auto", marginBottom: "3.5rem" }}
//           >
//             <table>
//               <thead>
//                 <tr>
//                   <th scope="col" className="itemAndBill">
//                     Dt. &amp; B.No
//                   </th>
//                   <th scope="col">Bags</th>
//                   <th scope="col">Weight</th>
//                   <th scope="col">Net Amt</th>
//                   <th
//                     scope="col"
//                     className="action"
//                     colSpan="3"
//                     style={{ opacity: 0 }}
//                   >
//                     Action
//                   </th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {filteredPurchaseList.map((item, index) => (
//                   <React.Fragment key={index}>
//                     <tr>
//                       <td colSpan="4">
//                         {item.partyName} - {item.town}
//                       </td>
//                       <td colSpan="3" className="mark">
//                         {item.mark}
//                       </td>
//                     </tr>
//                     <tr>
//                       <td>
//                         {item.billDate} &amp; {item.billNo1}
//                       </td>
//                       <td>{item.bags}</td>
//                       <td>{item.weight}</td>
//                       <td>{item.netAmt}</td>
//                       <td>
//                         <img
//                           src={jsonDownloader}
//                           alt="Json Download"
//                           onClick={() => handleJsonDownload(item)}
//                           style={{ cursor: "pointer" }}
//                         />
//                       </td>
//                       <td>
//                         <img
//                           src={edit}
//                           alt="Edit"
//                           onClick={() => handleEdit(index)}
//                           style={{ cursor: "pointer" }}
//                         />
//                       </td>
//                       <td>
//                         <img
//                           src={trash}
//                           alt="Delete"
//                           onClick={() => handleDelete(index)}
//                           style={{ cursor: "pointer" }}
//                         />
//                       </td>
//                     </tr>
//                   </React.Fragment>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//         )}

//         {selectedOption === "PlatformSale" && (
//           <div
//             className="item-list"
//             style={{ overflowX: "auto", marginBottom: "3.5rem" }}
//           >
//             <table>
//               <thead>
//                 <tr>
//                   <th scope="col" className="itemAndBill">
//                     Dt. &amp; B.No
//                   </th>
//                   <th scope="col">Bags</th>
//                   <th scope="col">Weight</th>
//                   <th scope="col">Net Amt</th>
//                   <th
//                     scope="col"
//                     className="action"
//                     colSpan="3"
//                     style={{ opacity: 0 }}
//                   >
//                     Action
//                   </th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {filteredPlatformSaleList.map((item, index) => (
//                   <React.Fragment key={index}>
//                     <tr>
//                       <td colSpan="4">
//                         {item.partyName} - {item.farmer}
//                       </td>
//                       <td colSpan="3" className="mark">
//                         {item.mark}
//                       </td>
//                     </tr>
//                     <tr>
//                       <td>
//                         {item.billDate} &amp; {item.billNo}
//                       </td>
//                       <td>{item.bags}</td>
//                       <td>{item.weight}</td>
//                       <td>{item.netAmt}</td>
//                       <td>
//                         <img
//                           src={jsonDownloader}
//                           alt="Json Download"
//                           onClick={() => handleJsonDownload(item)}
//                           style={{ cursor: "pointer" }}
//                         />
//                       </td>
//                       <td>
//                         <img
//                           src={edit}
//                           alt="Edit"
//                           onClick={() => handleEdit(index)}
//                           style={{ cursor: "pointer" }}
//                         />
//                       </td>
//                       <td>
//                         <img
//                           src={trash}
//                           alt="Delete"
//                           onClick={() => handleDelete(index)}
//                           style={{ cursor: "pointer" }}
//                         />
//                       </td>
//                     </tr>
//                   </React.Fragment>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//         )}

//         {selectedOption === "AcSale" && (
//           <div
//             className="item-list"
//             style={{ overflowX: "auto", marginBottom: "3.5rem" }}
//           >
//             <table>
//               <thead>
//                 <tr>
//                   <th scope="col" className="itemAndBill">
//                     Dt. &amp; B.No
//                   </th>
//                   <th scope="col">Bags</th>
//                   <th scope="col">Weight</th>
//                   <th scope="col">Net Amt</th>
//                   <th
//                     scope="col"
//                     className="action"
//                     colSpan="3"
//                     style={{ opacity: 0 }}
//                   >
//                     Action
//                   </th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {filteredAcSaleList.map((item, index) => (
//                   <React.Fragment key={index}>
//                     <tr>
//                       <td colSpan="4">
//                         {item.partyName} - {item.farmer}
//                       </td>
//                       <td colSpan="3" className="mark">
//                         {item.mark}
//                       </td>
//                     </tr>
//                     <tr>
//                       <td>
//                         {item.billDate} &amp; {item.billNo2 || item.billNo}
//                       </td>
//                       <td>{item.bags}</td>
//                       <td>{item.weight}</td>
//                       <td>{item.netAmt}</td>
//                       <td>
//                         <img
//                           src={jsonDownloader}
//                           alt="Json Download"
//                           onClick={() => handleJsonDownload(item)}
//                           style={{ cursor: "pointer" }}
//                         />
//                       </td>
//                       <td>
//                         <img
//                           src={edit}
//                           alt="Edit"
//                           onClick={() => handleEdit(index)}
//                           style={{ cursor: "pointer" }}
//                         />
//                       </td>
//                       <td>
//                         <img
//                           src={trash}
//                           alt="Delete"
//                           onClick={() => handleDelete(index)}
//                           style={{ cursor: "pointer" }}
//                         />
//                       </td>
//                     </tr>
//                   </React.Fragment>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//         )}
//       </main>
//     </div>
//   );
// };

// export default ListPage;
