import React, { useEffect, useState, useMemo } from "react";
import { NavLink } from "react-router-dom";
import * as XLSX from "xlsx"; // Import xlsx
import DatePicker from "react-datepicker"; // Import react-datepicker
import "react-datepicker/dist/react-datepicker.css"; // Import react-datepicker CSS
import logo from "../images/Logo.jpg";
import excelIcon from "../images/excel.svg";
import dateRange from "../images/DateRange.png";
import "./styles/AccountAndTransactions.css";
import "./styles/MarkAndDaily.css";

function MarkwiseReport() {
  const [purchaseData, setPurchaseData] = useState([]);
  const today = new Date();
  const [dateRangeState, setDateRange] = useState([today, today]);
  const [startDate, endDate] = dateRangeState;

  useEffect(() => {
    try {
      // Fetch and parse data from localStorage.Purchase
      const rawPurchaseData =
        JSON.parse(localStorage.getItem("Purchase")) || [];

      console.log("Raw Purchase Data:", rawPurchaseData); // Debugging line

      // Combine data (if there are multiple purchase sources, else directly assign)
      const combinedData = [...rawPurchaseData];

      // Helper function to convert DD-MM-YYYY to Date object
      const convertDateFormatToDate = (dateStr) => {
        if (!dateStr) return null;
        const [day, month, year] = dateStr.split("-");
        if (!day || !month || !year) {
          console.warn(`Invalid date format: ${dateStr}`);
          return null;
        }
        return new Date(`${year}-${month}-${day}`);
      };

      // Adjust startDate and endDate to include the entire days
      let adjustedStartDate = startDate ? new Date(startDate) : null;
      let adjustedEndDate = endDate ? new Date(endDate) : null;

      if (adjustedStartDate) adjustedStartDate.setHours(0, 0, 0, 0);
      if (adjustedEndDate) adjustedEndDate.setHours(23, 59, 59, 999);

      // Filter data based on the selected date range
      const filteredData = combinedData.filter((item) => {
        if (!item.billDate) return false;

        const billDate = convertDateFormatToDate(item.billDate);
        if (!billDate) return false;

        // If only startDate is selected
        if (adjustedStartDate && !adjustedEndDate) {
          return billDate >= adjustedStartDate;
        }

        // If only endDate is selected
        if (!adjustedStartDate && adjustedEndDate) {
          return billDate <= adjustedEndDate;
        }

        // If both dates are selected
        if (adjustedStartDate && adjustedEndDate) {
          return billDate >= adjustedStartDate && billDate <= adjustedEndDate;
        }

        return true; // Should not reach here
      });

      // Sort the filtered data by latest date first, then by Bill No.
      const sortedData = [...filteredData].sort((item1, item2) => {
        // Compare dates descending
        const date1 = convertDateFormatToDate(item1.billDate);
        const date2 = convertDateFormatToDate(item2.billDate);

        if (date1 > date2) return -1;
        if (date1 < date2) return 1;

        // Dates are equal, compare Bill No. ascending
        const billNo1 = getBillNo(item1) || "";
        const billNo2 = getBillNo(item2) || "";

        // Attempt to parse Bill No. as numbers
        const num1 = parseInt(billNo1, 10);
        const num2 = parseInt(billNo2, 10);

        if (!isNaN(num1) && !isNaN(num2)) {
          return num1 - num2;
        } else {
          // Compare as strings
          return billNo1.localeCompare(billNo2);
        }
      });

      setPurchaseData(sortedData);
    } catch (error) {
      console.error("Error fetching or processing purchase data:", error);
      setPurchaseData([]); // Optionally reset purchaseData on error
    }
  }, [startDate, endDate]);

  // Helper function to group items by purchase type
  const groupByPurchaseType = (data) => {
    return data.reduce((acc, item) => {
      // Replace "Unknown Purchase Type" with "UNPAID PURCHASE"
      const purchaseType = item.farmer || "UNPAID PURCHASE";
      if (!acc[purchaseType]) {
        acc[purchaseType] = [];
      }
      acc[purchaseType].push(item);
      return acc;
    }, {});
  };

  // Function to calculate totals for specific fields
  const calculateTotals = (items) => {
    return items.reduce(
      (totals, item) => {
        ["bags", "weight", "amount", "gAmt", "netAmt"].forEach((key) => {
          totals[key] += parseFloat(item[key]) || 0;
        });
        return totals;
      },
      { bags: 0, weight: 0, amount: 0, gAmt: 0, netAmt: 0 }
    );
  };

  // Function to retrieve the Bill No. from the item
  const getBillNo = (item) => {
    // Add more billNo fields here if they exist in the future
    return item.billNo || item.billNo1 || item.billNo2 || null;
  };

  const groupedData = useMemo(
    () => groupByPurchaseType(purchaseData),
    [purchaseData]
  );
  const totals = useMemo(() => calculateTotals(purchaseData), [purchaseData]);

  // Function to handle Excel download
  const downloadExcel = () => {
    if (purchaseData.length === 0) {
      alert(
        "No purchase data available to download for the selected date range."
      );
      return;
    }

    try {
      const workbook = XLSX.utils.book_new();
      const worksheetData = [];

      // Define the headers (Updated to reflect new columns)
      const headers = [
        "Purchase Type", // Changed from "Farmer Name" to "Purchase Type"
        "Bill Date",
        "Bill No.",
        "Trader Name",
        "Mark",
        "Bags",
        "Weight (Kgs)",
        "Amount",
        "Gunny Amount",
        "Net Amount",
      ];
      worksheetData.push(headers);

      // Iterate over each purchase type group
      Object.keys(groupedData).forEach((purchaseType) => {
        const purchaseTypeData = groupedData[purchaseType];
        const purchaseTypeTotals = calculateTotals(purchaseTypeData);

        // Add each transaction row
        purchaseTypeData.forEach((item, index) => {
          worksheetData.push([
            purchaseType, // This now represents "Purchase Type"
            item.billDate,
            getBillNo(item) || "N/A", // Ensure a default value
            item.partyName || "",
            item.mark || "",
            item.bags || 0,
            parseFloat(item.weight || 0).toFixed(2),
            parseFloat(item.amount || 0).toFixed(2),
            parseFloat(item.gAmt || 0).toFixed(2),
            parseFloat(item.netAmt || 0).toFixed(2),
          ]);
        });

        // Determine the label for the totals row
        const totalsLabel =
          purchaseType === "UNPAID PURCHASE"
            ? "Totals"
            : `Totals for ${purchaseType}`;

        // Add a subtotal row for the purchase type
        worksheetData.push([
          totalsLabel,
          "",
          "",
          "",
          "",
          purchaseTypeTotals.bags,
          purchaseTypeTotals.weight.toFixed(2),
          purchaseTypeTotals.amount.toFixed(2),
          purchaseTypeTotals.gAmt.toFixed(2),
          purchaseTypeTotals.netAmt.toFixed(2),
        ]);

        // Add an empty row for spacing
        worksheetData.push([""]);
      });

      // Add overall totals
      worksheetData.push([
        "Overall Totals",
        "",
        "",
        "",
        "",
        totals.bags,
        totals.weight.toFixed(2),
        totals.amount.toFixed(2),
        totals.gAmt.toFixed(2),
        totals.netAmt.toFixed(2),
      ]);

      // Create worksheet from data
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

      // Append worksheet to workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, "Markwise Report");

      // Generate buffer
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Create Blob and trigger download
      const dataBlob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });
      const url = window.URL.createObjectURL(dataBlob);
      const link = document.createElement("a");
      const formattedFromDate = startDate
        ? `${startDate.getFullYear()}-${`0${startDate.getMonth() + 1}`.slice(
            -2
          )}-${`0${startDate.getDate()}`.slice(-2)}`
        : "start";
      const formattedToDate = endDate
        ? `${endDate.getFullYear()}-${`0${endDate.getMonth() + 1}`.slice(
            -2
          )}-${`0${endDate.getDate()}`.slice(-2)}`
        : "end";
      link.href = url;
      link.setAttribute(
        "download",
        `MarkwiseReport_${formattedFromDate}_to_${formattedToDate}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url); // Free up memory
    } catch (error) {
      console.error("Error generating Excel file:", error);
      alert("An error occurred while generating the Excel file.");
    }
  };

  return (
    <div>
      <header className="main-header">
        <NavLink to="/">
          <img src={logo} alt="Kliq Soft" className="logo" />
        </NavLink>
      </header>
      <main>
        <div className="transactions">
          <p>Markwise Report</p>
        </div>
        <div className="goback">
          <NavLink to="/dashboard">
            <svg
              fill="#185492"
              className="left-arrow"
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 20 20"
            >
              <path d="M10,0A10,10,0,1,0,20,10,10,10,0,0,0,10,0Zm6,11H7.38l2.376,2.375L8.342,14.789,3.607,10.055,8.342,5.322,9.756,6.736,7.49,9H16Z" />
            </svg>
          </NavLink>
        </div>
        <div className="datePicker reports-datePicker">
          {/* Date Range Picker */}
          <label htmlFor="dateRange">
            <img src={dateRange} alt="Date Range" />
          </label>
          <DatePicker
            id="dateRange"
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => {
              setDateRange(update);
            }}
            maxDate={new Date()} // Ensure dates are not in the future
            isClearable={true}
            placeholderText="Select a date range"
          />

          {/* Download Button */}
          <button
            onClick={downloadExcel}
            className="reports-download-button"
            aria-label="Download Markwise Report as Excel"
          >
            <img src={excelIcon} alt="" aria-hidden="true" /> Download Excel
          </button>
        </div>
        <div
          className="item-list"
          style={{ overflowX: "auto", marginBottom: "3.5rem" }}
        >
          <table>
            <thead>
              <tr>
                {/* Updated Headers */}
                <th className="itemAndBill">Bill Date</th>
                <th className="itemAndBill">Bill No.</th>
                <th className="itemAndBill">Trader Name</th>
                <th>Mark</th>
                <th className="integer text-right">Bags</th>
                <th className="decimal text-right">Weight</th>
                <th className="decimal text-right">Amount</th>
                <th className="decimal text-right">Gunny Amount</th>
                <th className="decimal text-right">Net Amount</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(groupedData).length > 0 ? (
                Object.keys(groupedData).map((purchaseType, typeIndex) => {
                  const typeItems = groupedData[purchaseType];
                  const typeTotals = calculateTotals(typeItems);
                  return (
                    <React.Fragment
                      key={`purchaseType-${purchaseType}-${typeIndex}`}
                    >
                      {/* Purchase Type name row */}
                      <tr
                        key={`purchaseType-row-${purchaseType}-${typeIndex}`}
                        id="tr_party_name"
                      >
                        <td colSpan="9" style={{ fontWeight: "bold" }}>
                          {purchaseType}
                        </td>
                      </tr>
                      {/* Purchase Type's transactions */}
                      {typeItems.map((item, itemIndex) => (
                        <tr
                          id="tr_party_body"
                          key={`item-${purchaseType}-${getBillNo(
                            item
                          )}-${itemIndex}`}
                        >
                          <td>{item.billDate}</td>
                          <td>{getBillNo(item) || "N/A"}</td>
                          <td>{item.partyName || ""}</td>
                          <td>{item.mark || ""}</td>
                          <td className="integer text-right">{item.bags}</td>
                          <td className="decimal text-right">
                            {parseFloat(item.weight || 0).toFixed(2)}
                          </td>
                          <td className="decimal text-right">
                            {parseFloat(item.amount || 0).toFixed(2)}
                          </td>
                          <td className="decimal text-right">
                            {parseFloat(item.gAmt || 0).toFixed(2)}
                          </td>
                          <td className="decimal text-right">
                            {parseFloat(item.netAmt || 0).toFixed(2)}
                          </td>
                        </tr>
                      ))}
                      {/* Determine the label for the totals row */}
                      <tr
                        key={`totals-row-${purchaseType}-${typeIndex}`}
                        className="tr-summary"
                      >
                        <td colSpan="4" style={{ fontWeight: "bold" }}>
                          {purchaseType === "UNPAID PURCHASE"
                            ? "Totals"
                            : `Totals for ${purchaseType}`}
                        </td>
                        <td className="integer text-right">
                          {typeTotals.bags}
                        </td>
                        <td className="decimal text-right">
                          {typeTotals.weight.toFixed(2)}
                        </td>
                        <td className="decimal text-right">
                          {typeTotals.amount.toFixed(2)}
                        </td>
                        <td className="decimal text-right">
                          {typeTotals.gAmt.toFixed(2)}
                        </td>
                        <td className="decimal text-right">
                          {typeTotals.netAmt.toFixed(2)}
                        </td>
                      </tr>
                      {/* Add an empty row for spacing */}
                      <tr
                        key={`empty-row-${purchaseType}-${typeIndex}`}
                        className="tr-empty-row"
                      >
                        <td colSpan="9"></td>
                      </tr>
                    </React.Fragment>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="9" style={{ textAlign: "center" }}>
                    No purchase data available for the selected date range.
                  </td>
                </tr>
              )}
              {/* Overall totals */}
              {Object.keys(groupedData).length > 0 && (
                <tr key="overall-totals" className="tr-summary">
                  <td colSpan="4" style={{ fontWeight: "bold" }}>
                    Overall Totals
                  </td>
                  <td className="integer text-right">{totals.bags}</td>
                  <td className="decimal text-right">
                    {totals.weight.toFixed(2)}
                  </td>
                  <td className="decimal text-right">
                    {totals.amount.toFixed(2)}
                  </td>
                  <td className="decimal text-right">
                    {totals.gAmt.toFixed(2)}
                  </td>
                  <td className="decimal text-right">
                    {totals.netAmt.toFixed(2)}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </main>
    </div>
  );
}

export default MarkwiseReport;

// import React, { useEffect, useState, useMemo } from "react";
// import { NavLink } from "react-router-dom";
// import * as XLSX from "xlsx"; // Import xlsx
// import DatePicker from "react-datepicker"; // Import react-datepicker
// import "react-datepicker/dist/react-datepicker.css"; // Import react-datepicker CSS
// import logo from "../images/Logo.jpg";
// import excelIcon from "../images/excel.svg";
// import dateRange from "../images/DateRange.png";
// import "./styles/AccountAndTransactions.css";
// import "./styles/MarkAndDaily.css";

// function MarkwiseReport() {
//   const [purchaseData, setPurchaseData] = useState([]);
//   const today = new Date();
//   const [dateRangeState, setDateRange] = useState([today, today]);
//   const [startDate, endDate] = dateRangeState;

//   useEffect(() => {
//     try {
//       // Fetch and parse data from localStorage.Purchase
//       const rawPurchaseData =
//         JSON.parse(localStorage.getItem("Purchase")) || [];

//       // Combine data (if there are multiple purchase sources, else directly assign)
//       const combinedData = [...rawPurchaseData];

//       // Helper function to convert DD-MM-YYYY to Date object
//       const convertDateFormatToDate = (dateStr) => {
//         if (!dateStr) return null;
//         const [day, month, year] = dateStr.split("-");
//         if (!day || !month || !year) {
//           console.warn(`Invalid date format: ${dateStr}`);
//           return null;
//         }
//         return new Date(`${year}-${month}-${day}`);
//       };

//       // Adjust startDate and endDate to include the entire days
//       let adjustedStartDate = startDate ? new Date(startDate) : null;
//       let adjustedEndDate = endDate ? new Date(endDate) : null;

//       if (adjustedStartDate) adjustedStartDate.setHours(0, 0, 0, 0);
//       if (adjustedEndDate) adjustedEndDate.setHours(23, 59, 59, 999);

//       // Filter data based on the selected date range
//       const filteredData = combinedData.filter((item) => {
//         if (!item.billDate) return false;

//         const billDate = convertDateFormatToDate(item.billDate);
//         if (!billDate) return false;

//         // If only startDate is selected
//         if (adjustedStartDate && !adjustedEndDate) {
//           return billDate >= adjustedStartDate;
//         }

//         // If only endDate is selected
//         if (!adjustedStartDate && adjustedEndDate) {
//           return billDate <= adjustedEndDate;
//         }

//         // If both dates are selected
//         if (adjustedStartDate && adjustedEndDate) {
//           return billDate >= adjustedStartDate && billDate <= adjustedEndDate;
//         }

//         return true; // Should not reach here
//       });

//       // Sort the filtered data by latest date first, then by Bill No.
//       const sortedData = [...filteredData].sort((item1, item2) => {
//         // Compare dates descending
//         const date1 = convertDateFormatToDate(item1.billDate);
//         const date2 = convertDateFormatToDate(item2.billDate);

//         if (date1 > date2) return -1;
//         if (date1 < date2) return 1;

//         // Dates are equal, compare Bill No. ascending
//         const billNo1 = item1.billNo || item1.billNo2 || "";
//         const billNo2 = item2.billNo || item2.billNo2 || "";

//         // Attempt to parse Bill No. as numbers
//         const num1 = parseInt(billNo1, 10);
//         const num2 = parseInt(billNo2, 10);

//         if (!isNaN(num1) && !isNaN(num2)) {
//           return num1 - num2;
//         } else {
//           // Compare as strings
//           return billNo1.localeCompare(billNo2);
//         }
//       });

//       setPurchaseData(sortedData);
//     } catch (error) {
//       console.error("Error fetching or processing purchase data:", error);
//       setPurchaseData([]); // Optionally reset purchaseData on error
//     }
//   }, [startDate, endDate]);

//   // Helper function to group items by farmer name
//   const groupByFarmer = (data) => {
//     return data.reduce((acc, item) => {
//       // Replace "Unknown Farmer" with "UNPAID PURCHASE"
//       const farmer = item.farmer || "UNPAID PURCHASE";
//       if (!acc[farmer]) {
//         acc[farmer] = [];
//       }
//       acc[farmer].push(item);
//       return acc;
//     }, {});
//   };

//   // Function to calculate totals for specific fields
//   const calculateTotals = (items) => {
//     return items.reduce(
//       (totals, item) => {
//         ["bags", "weight", "amount", "gAmt", "netAmt"].forEach((key) => {
//           totals[key] += parseFloat(item[key]) || 0;
//         });
//         return totals;
//       },
//       { bags: 0, weight: 0, amount: 0, gAmt: 0, netAmt: 0 }
//     );
//   };

//   const groupedData = useMemo(
//     () => groupByFarmer(purchaseData),
//     [purchaseData]
//   );
//   const totals = useMemo(() => calculateTotals(purchaseData), [purchaseData]);

//   // Function to handle Excel download
//   const downloadExcel = () => {
//     if (purchaseData.length === 0) {
//       alert(
//         "No purchase data available to download for the selected date range."
//       );
//       return;
//     }

//     try {
//       const workbook = XLSX.utils.book_new();
//       const worksheetData = [];

//       // Define the headers (Updated to reflect new columns)
//       const headers = [
//         "Farmer Name", // Added Farmer Name as a separate column/grouping
//         "Bill Date",
//         "Bill No.",
//         "Trader Name",
//         "Mark",
//         "Bags",
//         "Weight (Kgs)",
//         "Amount",
//         "Gunny Amount",
//         "Net Amount",
//       ];
//       worksheetData.push(headers);

//       // Iterate over each farmer group
//       Object.keys(groupedData).forEach((farmerName) => {
//         const farmerData = groupedData[farmerName];
//         const farmerTotals = calculateTotals(farmerData);

//         // Add each transaction row
//         farmerData.forEach((item, index) => {
//           // Generate a unique key for each item
//           const uniqueKey = `item-${farmerName}-${
//             item.billNo || item.billNo2
//           }-${index}`;
//           worksheetData.push([
//             farmerName, // Farmer Name column
//             item.billDate,
//             item.billNo || item.billNo2 || "",
//             item.partyName || "",
//             item.mark || "",
//             item.bags || 0,
//             parseFloat(item.weight || 0).toFixed(2),
//             parseFloat(item.amount || 0).toFixed(2),
//             parseFloat(item.gAmt || 0).toFixed(2),
//             parseFloat(item.netAmt || 0).toFixed(2),
//           ]);
//         });

//         // Determine the label for the totals row
//         const totalsLabel =
//           farmerName === "UNPAID PURCHASE"
//             ? "Totals"
//             : `Totals for ${farmerName}`;

//         // Add a subtotal row for the farmer
//         worksheetData.push([
//           totalsLabel,
//           "",
//           "",
//           "",
//           "",
//           farmerTotals.bags,
//           farmerTotals.weight.toFixed(2),
//           farmerTotals.amount.toFixed(2),
//           farmerTotals.gAmt.toFixed(2),
//           farmerTotals.netAmt.toFixed(2),
//         ]);

//         // Add an empty row for spacing
//         worksheetData.push([""]);
//       });

//       // Add overall totals
//       worksheetData.push([
//         "Overall Totals",
//         "",
//         "",
//         "",
//         "",
//         totals.bags,
//         totals.weight.toFixed(2),
//         totals.amount.toFixed(2),
//         totals.gAmt.toFixed(2),
//         totals.netAmt.toFixed(2),
//       ]);

//       // Create worksheet from data
//       const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

//       // Append worksheet to workbook
//       XLSX.utils.book_append_sheet(workbook, worksheet, "Markwise Report");

//       // Generate buffer
//       const excelBuffer = XLSX.write(workbook, {
//         bookType: "xlsx",
//         type: "array",
//       });

//       // Create Blob and trigger download
//       const dataBlob = new Blob([excelBuffer], {
//         type: "application/octet-stream",
//       });
//       const url = window.URL.createObjectURL(dataBlob);
//       const link = document.createElement("a");
//       const formattedFromDate = startDate
//         ? `${startDate.getFullYear()}-${`0${startDate.getMonth() + 1}`.slice(
//             -2
//           )}-${`0${startDate.getDate()}`.slice(-2)}`
//         : "start";
//       const formattedToDate = endDate
//         ? `${endDate.getFullYear()}-${`0${endDate.getMonth() + 1}`.slice(
//             -2
//           )}-${`0${endDate.getDate()}`.slice(-2)}`
//         : "end";
//       link.href = url;
//       link.setAttribute(
//         "download",
//         `MarkwiseReport_${formattedFromDate}_to_${formattedToDate}.xlsx`
//       );
//       document.body.appendChild(link);
//       link.click();
//       document.body.removeChild(link);
//       window.URL.revokeObjectURL(url); // Free up memory
//     } catch (error) {
//       console.error("Error generating Excel file:", error);
//       alert("An error occurred while generating the Excel file.");
//     }
//   };

//   return (
//     <div>
//       <header className="main-header">
//         <NavLink to="/">
//           <img src={logo} alt="Kliq Soft" className="logo" />
//         </NavLink>
//       </header>
//       <main>
//         <div className="transactions">
//           <p>Markwise Report</p>
//         </div>
//         <div className="goback">
//           <NavLink to="/dashboard">
//             <svg
//               fill="#185492"
//               className="left-arrow"
//               xmlns="http://www.w3.org/2000/svg"
//               width="22"
//               height="22"
//               viewBox="0 0 20 20"
//             >
//               <path d="M10,0A10,10,0,1,0,20,10,10,10,0,0,0,10,0Zm6,11H7.38l2.376,2.375L8.342,14.789,3.607,10.055,8.342,5.322,9.756,6.736,7.49,9H16Z" />
//             </svg>
//           </NavLink>
//         </div>
//         <div className="datePicker reports-datePicker">
//           {/* Date Range Picker */}
//           <label htmlFor="dateRange">
//             <img src={dateRange} alt="Date Range" />
//           </label>
//           <DatePicker
//             id="dateRange"
//             selectsRange={true}
//             startDate={startDate}
//             endDate={endDate}
//             onChange={(update) => {
//               setDateRange(update);
//             }}
//             maxDate={new Date()} // Ensure dates are not in the future
//             isClearable={true}
//             placeholderText="Select a date range"
//           />

//           {/* Download Button */}
//           <button
//             onClick={downloadExcel}
//             className="reports-download-button"
//             aria-label="Download Markwise Report as Excel"
//           >
//             <img src={excelIcon} alt="" aria-hidden="true" /> Download Excel
//           </button>
//         </div>
//         <div
//           className="item-list"
//           style={{ overflowX: "auto", marginBottom: "3.5rem" }}
//         >
//           <table>
//             <thead>
//               <tr>
//                 {/* Updated Headers */}
//                 {/* Removed "Farmer Name/Date", "Item Name / Grade Name", and "Rate" */}
//                 <th>Bill Date</th>
//                 <th>Bill No.</th>
//                 <th>Trader Name</th>
//                 <th>Mark</th>
//                 <th className="integer text-right">Bags</th>
//                 <th className="decimal text-right">Weight</th>
//                 <th className="decimal text-right">Amount</th>
//                 <th className="decimal text-right">Gunny Amount</th>
//                 <th className="decimal text-right">Net Amount</th>
//               </tr>
//             </thead>
//             <tbody>
//               {Object.keys(groupedData).length > 0 ? (
//                 Object.keys(groupedData).map((farmerName, farmerIndex) => {
//                   const farmerItems = groupedData[farmerName];
//                   const farmerTotals = calculateTotals(farmerItems);
//                   return (
//                     <React.Fragment key={`farmer-${farmerName}-${farmerIndex}`}>
//                       {/* Farmer name row */}
//                       <tr
//                         key={`farmer-row-${farmerName}-${farmerIndex}`}
//                         className="tr-party-name"
//                       >
//                         <td colSpan="9" style={{ fontWeight: "bold" }}>
//                           {farmerName}
//                         </td>
//                       </tr>
//                       {/* Farmer's transactions */}
//                       {farmerItems.map((item, itemIndex) => (
//                         <tr
//                           className="tr-party-body"
//                           key={`item-${farmerName}-${
//                             item.billNo || item.billNo2
//                           }-${itemIndex}`}
//                         >
//                           <td>{item.billDate}</td>
//                           <td>{item.billNo || item.billNo2}</td>
//                           <td>{item.partyName}</td>
//                           <td>{item.mark}</td>
//                           <td className="integer text-right">{item.bags}</td>
//                           <td className="decimal text-right">
//                             {parseFloat(item.weight || 0).toFixed(2)}
//                           </td>
//                           <td className="decimal text-right">
//                             {parseFloat(item.amount || 0).toFixed(2)}
//                           </td>
//                           <td className="decimal text-right">
//                             {parseFloat(item.gAmt || 0).toFixed(2)}
//                           </td>
//                           <td className="decimal text-right">
//                             {parseFloat(item.netAmt || 0).toFixed(2)}
//                           </td>
//                         </tr>
//                       ))}
//                       {/* Determine the label for the totals row */}
//                       <tr
//                         key={`totals-row-${farmerName}-${farmerIndex}`}
//                         className="tr-summary"
//                       >
//                         <td colSpan="4" style={{ fontWeight: "bold" }}>
//                           {farmerName === "UNPAID PURCHASE"
//                             ? "Totals"
//                             : `Totals for ${farmerName}`}
//                         </td>
//                         <td className="integer text-right">
//                           {farmerTotals.bags}
//                         </td>
//                         <td className="decimal text-right">
//                           {farmerTotals.weight.toFixed(2)}
//                         </td>
//                         <td className="decimal text-right">
//                           {farmerTotals.amount.toFixed(2)}
//                         </td>
//                         <td className="decimal text-right">
//                           {farmerTotals.gAmt.toFixed(2)}
//                         </td>
//                         <td className="decimal text-right">
//                           {farmerTotals.netAmt.toFixed(2)}
//                         </td>
//                       </tr>
//                       {/* Add an empty row for spacing */}
//                       <tr
//                         key={`empty-row-${farmerName}-${farmerIndex}`}
//                         className="tr-empty-row"
//                       >
//                         <td colSpan="9"></td>
//                       </tr>
//                     </React.Fragment>
//                   );
//                 })
//               ) : (
//                 <tr>
//                   <td colSpan="9" style={{ textAlign: "center" }}>
//                     No purchase data available for the selected date range.
//                   </td>
//                 </tr>
//               )}
//               {/* Overall totals */}
//               {Object.keys(groupedData).length > 0 && (
//                 <tr key="overall-totals" className="tr-summary">
//                   <td colSpan="4" style={{ fontWeight: "bold" }}>
//                     Overall Totals
//                   </td>
//                   <td className="integer text-right">{totals.bags}</td>
//                   <td className="decimal text-right">
//                     {totals.weight.toFixed(2)}
//                   </td>
//                   <td className="decimal text-right">
//                     {totals.amount.toFixed(2)}
//                   </td>
//                   <td className="decimal text-right">
//                     {totals.gAmt.toFixed(2)}
//                   </td>
//                   <td className="decimal text-right">
//                     {totals.netAmt.toFixed(2)}
//                   </td>
//                 </tr>
//               )}
//             </tbody>
//           </table>
//         </div>
//       </main>
//     </div>
//   );
// }

// export default MarkwiseReport;

// import React, { useEffect, useState, useMemo } from "react";
// import { NavLink } from "react-router-dom";
// import * as XLSX from "xlsx"; // Import xlsx
// import DatePicker from "react-datepicker"; // Import react-datepicker
// import "react-datepicker/dist/react-datepicker.css"; // Import react-datepicker CSS
// import logo from "../images/Logo.jpg";
// import excelIcon from "../images/excel.svg";
// import dateRange from "../images/DateRange.png";
// import "./styles/AccountAndTransactions.css";
// import "./styles/MarkAndDaily.css";

// function MarkwiseReport() {
//   const [purchaseData, setPurchaseData] = useState([]);
//   const today = new Date();
//   const [dateRangeState, setDateRange] = useState([today, today]);
//   const [startDate, endDate] = dateRangeState;

//   useEffect(() => {
//     try {
//       // Fetch and parse data from localStorage.Purchase
//       const rawPurchaseData =
//         JSON.parse(localStorage.getItem("Purchase")) || [];

//       // Optionally, add any data normalization here if necessary
//       // For example, ensure all required fields are present

//       // Combine data (if there are multiple purchase sources, else directly assign)
//       const combinedData = [...rawPurchaseData];

//       // Helper function to convert DD-MM-YYYY to Date object
//       const convertDateFormatToDate = (dateStr) => {
//         if (!dateStr) return null;
//         const [day, month, year] = dateStr.split("-");
//         if (!day || !month || !year) {
//           console.warn(`Invalid date format: ${dateStr}`);
//           return null;
//         }
//         return new Date(`${year}-${month}-${day}`);
//       };

//       // Adjust startDate and endDate to include the entire days
//       let adjustedStartDate = startDate ? new Date(startDate) : null;
//       let adjustedEndDate = endDate ? new Date(endDate) : null;

//       if (adjustedStartDate) adjustedStartDate.setHours(0, 0, 0, 0);
//       if (adjustedEndDate) adjustedEndDate.setHours(23, 59, 59, 999);

//       // Filter data based on the selected date range
//       const filteredData = combinedData.filter((item) => {
//         if (!item.billDate) return false;

//         const billDate = convertDateFormatToDate(item.billDate);
//         if (!billDate) return false;

//         // If only startDate is selected
//         if (adjustedStartDate && !adjustedEndDate) {
//           return billDate >= adjustedStartDate;
//         }

//         // If only endDate is selected
//         if (!adjustedStartDate && adjustedEndDate) {
//           return billDate <= adjustedEndDate;
//         }

//         // If both dates are selected
//         if (adjustedStartDate && adjustedEndDate) {
//           return billDate >= adjustedStartDate && billDate <= adjustedEndDate;
//         }

//         return true; // Should not reach here
//       });

//       // Sort the filtered data by latest date first, then by Bill No.
//       const sortedData = [...filteredData].sort((item1, item2) => {
//         // Compare dates descending
//         const date1 = convertDateFormatToDate(item1.billDate);
//         const date2 = convertDateFormatToDate(item2.billDate);

//         if (date1 > date2) return -1;
//         if (date1 < date2) return 1;

//         // Dates are equal, compare Bill No. ascending
//         const billNo1 = item1.billNo || item1.billNo2 || "";
//         const billNo2 = item2.billNo || item2.billNo2 || "";

//         // Attempt to parse Bill No. as numbers
//         const num1 = parseInt(billNo1, 10);
//         const num2 = parseInt(billNo2, 10);

//         if (!isNaN(num1) && !isNaN(num2)) {
//           return num1 - num2;
//         } else {
//           // Compare as strings
//           return billNo1.localeCompare(billNo2);
//         }
//       });

//       setPurchaseData(sortedData);
//     } catch (error) {
//       console.error("Error fetching or processing purchase data:", error);
//       setPurchaseData([]); // Optionally reset purchaseData on error
//     }
//   }, [startDate, endDate]);

//   // Helper function to group items by farmer name
//   const groupByFarmer = (data) => {
//     return data.reduce((acc, item) => {
//       const farmer = item.farmer || "Unknown Farmer";
//       if (!acc[farmer]) {
//         acc[farmer] = [];
//       }
//       acc[farmer].push(item);
//       return acc;
//     }, {});
//   };

//   // Function to calculate totals for specific fields
//   const calculateTotals = (items) => {
//     return items.reduce(
//       (totals, item) => {
//         ["bags", "weight", "amount", "gAmt", "netAmt"].forEach((key) => {
//           totals[key] += parseFloat(item[key]) || 0;
//         });
//         return totals;
//       },
//       { bags: 0, weight: 0, amount: 0, gAmt: 0, netAmt: 0 }
//     );
//   };

//   const groupedData = useMemo(
//     () => groupByFarmer(purchaseData),
//     [purchaseData]
//   );
//   const totals = useMemo(() => calculateTotals(purchaseData), [purchaseData]);

//   // Function to handle Excel download
//   const downloadExcel = () => {
//     if (purchaseData.length === 0) {
//       alert(
//         "No purchase data available to download for the selected date range."
//       );
//       return;
//     }

//     try {
//       const workbook = XLSX.utils.book_new();
//       const worksheetData = [];

//       // Define the headers
//       const headers = [
//         "Farmer Name",
//         "Bill Date",
//         "Bill No.",
//         "Trader Name",
//         "Item Name / Grade Name",
//         "Mark",
//         "Bags",
//         "Weight (Kgs)",
//         "Rate",
//         "Amount",
//         "Gunny Amount",
//         "Net Amount",
//       ];
//       worksheetData.push(headers);

//       // Iterate over each farmer group
//       Object.keys(groupedData).forEach((farmerName) => {
//         const farmerData = groupedData[farmerName];
//         const farmerTotals = calculateTotals(farmerData);

//         // Add a row for the farmer name
//         worksheetData.push([
//           farmerName,
//           "", // Bill Date
//           "", // Bill No.
//           "", // Trader Name
//           "", // Item Name / Grade Name
//           "", // Mark
//           "", // Bags
//           "", // Weight (Kgs)
//           "", // Rate
//           "", // Amount
//           "", // Gunny Amount
//           "", // Net Amount
//         ]);

//         // Add each transaction row
//         farmerData.forEach((item) => {
//           worksheetData.push([
//             "", // Farmer Name column left blank for transactions
//             item.billDate,
//             item.billNo || item.billNo2 || "",
//             item.partyName || "",
//             `${item.itemName} / ${item.gradeName}`,
//             item.mark || "",
//             item.bags || 0,
//             parseFloat(item.weight || 0).toFixed(2),
//             parseFloat(item.rate || 0).toFixed(2),
//             parseFloat(item.amount || 0).toFixed(2),
//             parseFloat(item.gAmt || 0).toFixed(2),
//             parseFloat(item.netAmt || 0).toFixed(2),
//           ]);
//         });

//         // Add a subtotal row for the farmer
//         worksheetData.push([
//           `Totals for ${farmerName}`,
//           "",
//           "",
//           "",
//           "",
//           "",
//           farmerTotals.bags,
//           farmerTotals.weight.toFixed(2),
//           "",
//           farmerTotals.amount.toFixed(2),
//           farmerTotals.gAmt.toFixed(2),
//           farmerTotals.netAmt.toFixed(2),
//         ]);

//         // Add an empty row for spacing
//         worksheetData.push([""]);
//       });

//       // Add overall totals
//       worksheetData.push([
//         "Overall Totals",
//         "",
//         "",
//         "",
//         "",
//         "",
//         totals.bags,
//         totals.weight.toFixed(2),
//         "",
//         totals.amount.toFixed(2),
//         totals.gAmt.toFixed(2),
//         totals.netAmt.toFixed(2),
//       ]);

//       // Create worksheet from data
//       const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

//       // Append worksheet to workbook
//       XLSX.utils.book_append_sheet(workbook, worksheet, "Markwise Report");

//       // Generate buffer
//       const excelBuffer = XLSX.write(workbook, {
//         bookType: "xlsx",
//         type: "array",
//       });

//       // Create Blob and trigger download
//       const dataBlob = new Blob([excelBuffer], {
//         type: "application/octet-stream",
//       });
//       const url = window.URL.createObjectURL(dataBlob);
//       const link = document.createElement("a");
//       const formattedFromDate = startDate
//         ? `${startDate.getFullYear()}-${`0${startDate.getMonth() + 1}`.slice(
//             -2
//           )}-${`0${startDate.getDate()}`.slice(-2)}`
//         : "start";
//       const formattedToDate = endDate
//         ? `${endDate.getFullYear()}-${`0${endDate.getMonth() + 1}`.slice(
//             -2
//           )}-${`0${endDate.getDate()}`.slice(-2)}`
//         : "end";
//       link.href = url;
//       link.setAttribute(
//         "download",
//         `MarkwiseReport_${formattedFromDate}_to_${formattedToDate}.xlsx`
//       );
//       document.body.appendChild(link);
//       link.click();
//       document.body.removeChild(link);
//       window.URL.revokeObjectURL(url); // Free up memory
//     } catch (error) {
//       console.error("Error generating Excel file:", error);
//       alert("An error occurred while generating the Excel file.");
//     }
//   };

//   return (
//     <div>
//       <header className="main-header">
//         <NavLink to="/">
//           <img src={logo} alt="Kliq Soft" className="logo" />
//         </NavLink>
//       </header>
//       <main>
//         <div className="transactions">
//           <p>Markwise Report</p>
//         </div>
//         <div className="goback">
//           <NavLink to="/dashboard">
//             <svg
//               fill="#185492"
//               className="left-arrow"
//               xmlns="http://www.w3.org/2000/svg"
//               width="22"
//               height="22"
//               viewBox="0 0 20 20"
//             >
//               <path d="M10,0A10,10,0,1,0,20,10,10,10,0,0,0,10,0Zm6,11H7.38l2.376,2.375L8.342,14.789,3.607,10.055,8.342,5.322,9.756,6.736,7.49,9H16Z" />
//             </svg>
//           </NavLink>
//         </div>
//         <div className="datePicker reports-datePicker">
//           {/* Date Range Picker */}
//           <label htmlFor="dateRange">
//             <img src={dateRange} alt="Date Range" />
//           </label>
//           <DatePicker
//             id="dateRange"
//             selectsRange={true}
//             startDate={startDate}
//             endDate={endDate}
//             onChange={(update) => {
//               setDateRange(update);
//             }}
//             maxDate={new Date()} // Ensure dates are not in the future
//             isClearable={true}
//             placeholderText="Select a date range"
//           />

//           {/* Download Button */}
//           <button
//             onClick={downloadExcel}
//             className="reports-download-button"
//             aria-label="Download Markwise Report as Excel"
//           >
//             <img src={excelIcon} alt="" aria-hidden="true" /> Download Excel
//           </button>
//         </div>
//         <div
//           className="item-list"
//           style={{ overflowX: "auto", marginBottom: "3.5rem" }}
//         >
//           <table>
//             <thead>
//               <tr>
//                 <th className="farmerstyle">Farmer Name/Date</th>
//                 <th className="billNoStyle">Bill No.</th>
//                 <th className="traderStyle">Trader Name</th>
//                 <th className="itemStyle">Item Name / Grade Name</th>
//                 <th>Mark</th>
//                 <th className="integer text-right">Bags</th>
//                 <th className="decimal text-right">Weight (Kgs)</th>
//                 <th className="decimal text-right">Rate</th>
//                 <th className="decimal text-right">Amount</th>
//                 <th className="decimal text-right">Gunny Amount</th>
//                 <th className="decimal text-right">Net Amount</th>
//               </tr>
//             </thead>
//             <tbody>
//               {Object.keys(groupedData).length > 0 ? (
//                 Object.keys(groupedData).map((farmerName) => {
//                   const farmerItems = groupedData[farmerName];
//                   const farmerTotals = calculateTotals(farmerItems);
//                   return (
//                     <React.Fragment key={farmerName}>
//                       {/* Farmer name row */}
//                       <tr className="tr-party-name">
//                         <td colSpan="11" style={{ fontWeight: "bold" }}>
//                           {farmerName}
//                         </td>
//                       </tr>
//                       {/* Farmer's transactions */}
//                       {farmerItems.map((item) => (
//                         <tr
//                           className="tr-party-body"
//                           key={item.billNo || item.billNo2}
//                         >
//                           <td>{item.billDate}</td>
//                           <td>{item.billNo || item.billNo2}</td>
//                           <td>{item.partyName}</td>
//                           <td>
//                             {item.itemName} / {item.gradeName}
//                           </td>
//                           <td>{item.mark}</td>
//                           <td className="integer text-right">{item.bags}</td>
//                           <td className="decimal text-right">
//                             {parseFloat(item.weight || 0).toFixed(2)}
//                           </td>
//                           <td className="decimal text-right">
//                             {parseFloat(item.rate || 0).toFixed(2)}
//                           </td>
//                           <td className="decimal text-right">
//                             {parseFloat(item.amount || 0).toFixed(2)}
//                           </td>
//                           <td className="decimal text-right">
//                             {parseFloat(item.gAmt || 0).toFixed(2)}
//                           </td>
//                           <td className="decimal text-right">
//                             {parseFloat(item.netAmt || 0).toFixed(2)}
//                           </td>
//                         </tr>
//                       ))}
//                       {/* Farmer totals row */}
//                       <tr className="tr-summary">
//                         <td colSpan="5" style={{ fontWeight: "bold" }}>
//                           Totals for {farmerName}
//                         </td>
//                         <td className="integer text-right">
//                           {farmerTotals.bags}
//                         </td>
//                         <td className="decimal text-right">
//                           {farmerTotals.weight.toFixed(2)}
//                         </td>
//                         <td></td>
//                         <td className="decimal text-right">
//                           {farmerTotals.amount.toFixed(2)}
//                         </td>
//                         <td className="decimal text-right">
//                           {farmerTotals.gAmt.toFixed(2)}
//                         </td>
//                         <td className="decimal text-right">
//                           {farmerTotals.netAmt.toFixed(2)}
//                         </td>
//                       </tr>
//                     </React.Fragment>
//                   );
//                 })
//               ) : (
//                 <tr>
//                   <td colSpan="11" style={{ textAlign: "center" }}>
//                     No purchase data available for the selected date range.
//                   </td>
//                 </tr>
//               )}
//               {/* Overall totals */}
//               {Object.keys(groupedData).length > 0 && (
//                 <tr className="tr-summary">
//                   <td colSpan="5" style={{ fontWeight: "bold" }}>
//                     Overall Totals
//                   </td>
//                   <td className="integer text-right">{totals.bags}</td>
//                   <td className="decimal text-right">
//                     {totals.weight.toFixed(2)}
//                   </td>
//                   <td></td>
//                   <td className="decimal text-right">
//                     {totals.amount.toFixed(2)}
//                   </td>
//                   <td className="decimal text-right">
//                     {totals.gAmt.toFixed(2)}
//                   </td>
//                   <td className="decimal text-right">
//                     {totals.netAmt.toFixed(2)}
//                   </td>
//                 </tr>
//               )}
//             </tbody>
//           </table>
//         </div>
//       </main>
//     </div>
//   );
// }

// export default MarkwiseReport;
