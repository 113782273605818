import React, { useState, useEffect, useRef } from "react";
import "./styles/Modal.css";

function ModalWindow({ serialCount, onClose, initialWeights, startSerial }) {
  const [manualSrNoChange, setManualSrNoChange] = useState(false);
  const [startFrom, setStartFrom] = useState(startSerial || 1);
  const [inputs, setInputs] = useState(
    initialWeights || Array.from({ length: serialCount }, () => "")
  );
  const firstInputRef = useRef(null); // Create a ref for the first input

  useEffect(() => {
    setInputs((prevInputs) => {
      const newInputs = Array.from(
        { length: serialCount },
        (_, i) => prevInputs[i] || ""
      );
      return newInputs;
    });
  }, [serialCount, initialWeights]);

  useEffect(() => {
    // Use setTimeout to ensure the focus happens after render
    setTimeout(() => {
      if (firstInputRef.current) {
        firstInputRef.current.focus();
      }
    }, 0); // Delay of 0 ensures it happens after the component renders
  }, []);

  const handleInputChange = (e, index) => {
    const value = e.target.value.replace(/[^0-9.]/g, "");
    setInputs((prevInputs) => {
      const newInputs = [...prevInputs];
      newInputs[index] = value;
      return newInputs;
    });
  };

  const handleInputBlur = (e, index) => {
    let value = parseFloat(e.target.value).toFixed(3);
    if (isNaN(value)) {
      value = "";
    }
    setInputs((prevInputs) => {
      const newInputs = [...prevInputs];
      newInputs[index] = value;
      return newInputs;
    });
  };

  const calculateGrossWeight = () => {
    return inputs
      .reduce((acc, val) => acc + parseFloat(val || 0), 0)
      .toFixed(3);
  };

  const calculateTareWeight = () => {
    // Assuming each bag has a tare weight of 1 unit (you can adjust this value)
    const tareWeightPerBag = 1;
    return (serialCount * tareWeightPerBag).toFixed(3);
  };

  const calculateNetWeight = (grossWeight, tareWeight) => {
    return (parseFloat(grossWeight) - parseFloat(tareWeight)).toFixed(3);
  };

  const grossWeight = calculateGrossWeight();
  const tareWeight = calculateTareWeight();
  const netWeight = calculateNetWeight(grossWeight, tareWeight);

  const handleClose = () => {
    onClose({ weights: inputs, startFrom, netWeight, tareWeight });
  };

  return (
    <div className="modal_overlay">
      <div id="modal_window" className="modal_window">
        <h3>Weight List</h3>
        <div className="row rowMargin">
          <p>Sr.No Start From</p>
          <input
            type="text"
            name="SrNo_Input"
            value={startFrom}
            id="SrNo_Input"
            disabled={!manualSrNoChange}
            onChange={(e) => setStartFrom(parseInt(e.target.value, 10) || 1)}
          />
        </div>
        <div className="manualCheck">
          <input
            type="checkbox"
            name="SrNo_manualCheck"
            id="manualCheck"
            checked={manualSrNoChange}
            onChange={(e) => setManualSrNoChange(e.target.checked)}
          />
          <label htmlFor="manualCheck">Manual Sr.No Change</label>
        </div>
        <div className="table-container">
          <table>
            <thead>
              <tr className="row">
                <th className="col srNo" scope="col">
                  Sr.No
                </th>
                <th className="col" scope="col">
                  Weight
                </th>
              </tr>
            </thead>
            <tbody>
              {inputs.map((weight, index) => (
                <tr key={index} className="row">
                  <td className="col srNo">{index + startFrom}</td>
                  <td className="col">
                    <input
                      type="text"
                      className="numeric-input three-decimal"
                      value={weight}
                      inputMode="numeric"
                      ref={index === 0 ? firstInputRef : null} // Assign ref to the first input
                      onChange={(e) => handleInputChange(e, index)}
                      onBlur={(e) => handleInputBlur(e, index)}
                      placeholder="0.000"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="row rowGroup">
          <p className="col">Gross Wgt</p>
          <input
            className="col numeric-input three-decimal"
            type="text"
            value={grossWeight}
            readOnly
          />
        </div>
        <div className="row rowGroup">
          <p className="col">Tare Wgt</p>
          <input
            className="col numeric-input three-decimal"
            type="text"
            value={tareWeight}
            readOnly
          />
        </div>
        <div className="row rowGroup">
          <p className="col">Net Wgt</p>
          <input
            className="col numeric-input three-decimal"
            type="text"
            value={netWeight}
            readOnly
          />
        </div>
        <div className="row">
          <button onClick={handleClose}>Ok</button>
          <button onClick={() => onClose(null)}>Cancel</button>
        </div>
      </div>
    </div>
  );
}

export default ModalWindow;
