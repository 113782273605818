import React, { useState, useEffect } from "react";
import "./styles/ModalPopUp.css"; // Import your modal styles

const ChargeModal = ({ isOpen, onClose, chargesData, onSave }) => {
  const [charges, setCharges] = useState(chargesData);

  // Sync the state when the modal opens or chargesData changes
  useEffect(() => {
    setCharges(chargesData);
  }, [chargesData]);

  // Handle the change of rate input
  const handleRateChange = (id, newRate) => {
    setCharges((prevCharges) =>
      prevCharges.map((charge) =>
        charge.id === id ? { ...charge, rate: newRate } : charge
      )
    );
  };

  // Handle save and close
  const handleSaveAndClose = () => {
    onSave(charges); // Pass the updated charges back to the parent
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div id="myModal" className="modal" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="table-container">
          <table className="modaltable">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Charge Name</th>
                <th>Rate</th>
                <th>Value</th>
                <th>Adj.A/c Name</th>
                <th>GST %</th>
                <th>GST Amt</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {charges.map((charge, index) => (
                <tr key={charge.id}>
                  <td>{index + 1}</td>
                  <td>{charge.chargeName}</td>
                  <td>
                    <input
                      type="text"
                      value={charge.rate}
                      onChange={(e) =>
                        handleRateChange(charge.id, e.target.value)
                      }
                      className="rate-input"
                    />
                  </td>
                  <td>{charge.value}</td>
                  <td>{charge.adjAcName}</td>
                  <td>{charge.gst}</td>
                  <td>{charge.gstAmt}</td>
                  <td>{charge.total}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="button-container">
          <button id="okButton" onClick={handleSaveAndClose}>
            OK
          </button>
          <button id="cancelButton" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChargeModal;
